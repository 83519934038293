import React, { useEffect, useState, useRef } from "react";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import AdminCaseService from "../../../services/Case/AdminCase/adminCase.service";
import SharedCaseService from "../../../services/Shared/shared.service";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import moment from "moment";
import {
  Button,
  Drawer,
  Menu,
  Modal,
  Popover,
  Table,
  TablePaginationConfig,
  Tooltip,
  Input,
  Select,
  Row,
  Col,
} from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import {
  DownloadOutlined,
  UserAddOutlined,
  FormOutlined,
  CloudDownloadOutlined,
  EllipsisOutlined,
  CopyOutlined,
  EditOutlined,
  WhatsAppOutlined,
  ShareAltOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import CaseAssignmentForm from "./CaseAssignmentForm";
import {
  copyCaseDetailsToClipboard,
  stopPropogateEvent,
} from "../../../shared/utils/eventUtils";
import CaseOverview from "../../../shared/components/CaseOverview";
import "./adminCases.scss";
import CaseFilterForm from "../../../shared/components/CaseFilterForm";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { CaseBodyPart } from "../../../models/CaseBodyPart/caseBodyPart.model";
import ReportForm from "../../../shared/components/ReportForm";
import { Admin } from "../../../models/Admin/admin.model";
import ProfileService from "../../../services/Profile/profile.service";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import ReportService from "../../../services/Report/report.service";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import {
  formatStatus,
  getCustomCellStyles,
  getInitialFiltersFromURL,
} from "../../../shared/utils/dataFormatConverter";
import { generatePath, Link, useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import CaseContainer from "../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../store/reducers/caseReducer";
import { useLocation } from "react-router-dom";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { PaginationMeta } from "../../../models/PaginationMeta/paginationMeta.model";
import { AdminFeatureEnum } from "../../../enums/AdminFeature.enum";
import { AdminTypeEnum } from "../../../enums/AdminType.enum";
import CaseForm from "../ScanCenterCases/CaseForm";
import CaseSharing from "../../../shared/components/CaseSharing";
import { ColumnsType } from "antd/lib/table";
import ContextMenu from "../../../shared/components/ContextMenu";
import { Tag } from "../../../models/Tag/tag.model";
import TagLegend from "../../../shared/components/TagLegend";

interface AdminCasesProps extends CaseReducerProps {}
interface AdminCasesProps extends AuthReducerProps {}

function AdminCases({
  newCaseDetail,
  updatedCaseDetail,
  adminType,
}: AdminCasesProps) {
  const location = useLocation();

  const [caseDetails, setCaseDetails] = useState<CaseDetail[]>([]);

  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>(
    Object.assign(new PaginationMeta(), {
      currentPage: 1,
      totalCount: 0,
      totalPages: 0,
    })
  );

  const [activeCase, setActiveCase] = useState<CaseDetail | undefined>();

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [downloadZipLoading, setDownloadZipLoading] = useState(false);

  const [showCaseOverview, setShowCaseOverview] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);

  const [showCaseForm, setShowCaseForm] = useState(false);

  const [me, setMe] = useState<Admin>();

  const handleToggleReportForm = () => setShowReportForm(!showReportForm);

  const assignmentPopoverRef = useRef<any>(null);

  const viewPopoverRef = useRef<any>(null);

  const [activePopoverId, setActivePopoverId] = useState<number | null>(null);

  const history = useHistory();

  const [menuVisible, setMenuVisible] = useState(false);

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const [selectedRow, setSelectedRow] = useState<any>(null);

  const menuRef = useRef<HTMLDivElement>(null);

  const [formValues, setFormValues] = useState<CaseFilterParams>(
    Object.assign(new CaseFilterParams(), {
      ...new CaseFilterParams(),
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      scanCenterBranchId: undefined,
    })
  );

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const resizingColumnRef = useRef(null);

  const startXRef = useRef(0);

  const startWidthRef = useRef(0);

  // Cleanup on component unmount to clear the debounce timer
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handleToggleCaseForm = () => setShowCaseForm(!showCaseForm);

  const handleCloseCaseOverview = () => {
    setActiveCase(undefined);
    setShowCaseOverview(false);
  };

  const handleViewAccessSuccess = () => {
    if (viewPopoverRef?.current && viewPopoverRef.current) {
      viewPopoverRef.current.setPopupVisible(false);
    }
    setActiveCase(undefined);
  };

  const handleRowContextMenu = (
    record: CaseDetail,
    event: React.MouseEvent
  ) => {
    event.preventDefault();
    setSelectedRow(record);
    setMenuPosition({ x: event.pageX, y: event.pageY });
    setMenuVisible(true);
  };

  const handleRowChange = (caseDetail: CaseDetail, _: number | undefined) => {
    return {
      onClick: (event: any) => {
        event.preventDefault(); // Prevent default behavior if needed
        if (caseDetail?.id) {
          window.open(
            generatePath(AppRoutes.CASE_DETAIL, {
              caseId: caseDetail.id,
            }),
            "_blank" // This opens the URL in a new tab
          );
        }
      },
      onContextMenu: (event: React.MouseEvent) =>
        handleRowContextMenu(caseDetail, event),
    };
  };

  const handlePageChange = (pagination: TablePaginationConfig) => {
    const values = Object.assign(new CaseFilterParams(), {
      ...formValues,
      page: pagination.current,
      perPage: pagination.pageSize,
    });
    updateUrlParams(values);
  };

  // Update URL with current filter values
  const updateUrlParams = (values: CaseFilterParams) => {
    const searchParams = new URLSearchParams();
    // Add non-empty filter values to URL
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        searchParams.set(key, String(value));
      }
    });
    // Update URL without page reload
    history.replace(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleFetchCases = (formValues: CaseFilterParams) => {
    setLoading(true);
    AdminCaseService.fetchAdminCases(
      formValues,
      (caseDetails: CaseDetail[], paginationMeta: PaginationMeta) => {
        setCaseDetails(caseDetails);
        setPaginationMeta(paginationMeta);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleAssignmentSuccess = (caseDetail: CaseDetail) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseDetail.id
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex] = caseDetail;
    }
    setCaseDetails([...caseDetails]);
    if (assignmentPopoverRef?.current && assignmentPopoverRef.current) {
      assignmentPopoverRef.current.setPopupVisible(false);
    }
  };

  const handleCaseUpdateSuccess = (caseDetail: CaseDetail) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseDetail.id
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex] = caseDetail;
    }
    setCaseDetails([...caseDetails]);
    setActiveCase(caseDetail);
  };

  const handleDownloadReport = (
    caseDetail: CaseDetail,
    fileType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => {
    setActiveCase(caseDetail);
    setDownloadLoading(true);
    if (caseDetail?.id) {
      const apiMethod = ReportService.downloadReport;
      apiMethod(
        fileType,
        caseDetail,
        withLetterHead,
        (reportUrl: string) => {
          window.open(reportUrl, "_blank");
        },
        () => {},
        () => {
          setActiveCase(undefined);
          setDownloadLoading(false);
        }
      );
    }
  };

  const handleDownloadDetails = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      setDownloadZipLoading(true);
      AdminCaseService.downloadAdminCase(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          window.open(targetUrl, "_blank");
        },
        () => {},
        () => {
          setDownloadZipLoading(false);
        }
      );
    }
  };

  const handleCaseShareLink = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      SharedCaseService.getCaseShareLink(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          copyCaseDetailsToClipboard(caseDetail, targetUrl);
        },
        () => {},
        () => {}
      );
    }
  };

  // Initial data fetch with URL parameters
  useEffect(() => {
    const initialFilters = getInitialFiltersFromURL(location.search);
    setFormValues(initialFilters);
    handleFetchCases(initialFilters);
  }, [location.search]); // Re-fetch when URL changes

  useEffect(() => {
    ProfileService.getProfile(
      UserRoleEnum.ADMIN,
      (admin: Admin) => {
        setMe(admin);
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    if (newCaseDetail) {
      handleFetchCases(formValues);
      // caseDetails.unshift(newCaseDetail);
      // setCaseDetails([...caseDetails]);
    }
  }, [newCaseDetail]);

  useEffect(() => {
    if (updatedCaseDetail) {
      const caseIndex = caseDetails.findIndex(
        (caseDetail) => caseDetail.id === updatedCaseDetail.id
      );
      if (caseIndex >= 0) {
        caseDetails[caseIndex] = updatedCaseDetail;
        setCaseDetails([...caseDetails]);
      }
      // handleFetchCases(formValues);
    }
  }, [updatedCaseDetail]);

  const handleEditCase = (caseDetail: CaseDetail) => () => {
    setActiveCase(caseDetail);
    handleToggleCaseForm();
  };

  const handleUploadCaseSuccess = (caseDetail: CaseDetail) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseDetail.id
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex] = caseDetail;
    } else {
      caseDetails.unshift(caseDetail);
    }
    setCaseDetails([...caseDetails]);
  };

  const handleUpdateTags = (caseId: number, tags: Tag[]) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseId
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex].tags = tags;
    }
    setCaseDetails([...caseDetails]);
  };

  const handleMouseDown = (e: any, index: any) => {
    resizingColumnRef.current = index;
    startXRef.current = e.clientX;
    startWidthRef.current = columns[index].width;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: any) => {
    if (resizingColumnRef.current !== null) {
      const newWidth = startWidthRef.current + (e.clientX - startXRef.current);
      const newColumns = [...columns];
      newColumns[resizingColumnRef.current].width = Math.max(newWidth, 100);
      setColumns(newColumns);
    }
  };

  const handleMouseUp = () => {
    resizingColumnRef.current = null;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const adminFeatures = me?.adminFeatures?.map(
      (adminFeature) => adminFeature.feature
    );

    setColumns([
      {
        title: "Tags",
        dataIndex: "id",
        key: "id",
        width: 75,
        ellipsis: true,
        fixed: screenWidth > 900 ? "left" : undefined,
        render: (_: number, caseDetail: CaseDetail) => (
          <div className="admin-cases__tags">
            {caseDetail?.tags?.map((tag) => (
              <Tooltip title={tag.formattedName} key={tag.id}>
                <span
                  className="admin-cases__tag-item"
                  style={{ backgroundColor: tag.color }}
                ></span>
              </Tooltip>
            ))}
          </div>
        ),
      },
      {
        title: "Patient ID",
        dataIndex: "patientId",
        key: "patientId",
        width: 100,
        ellipsis: true,
        render: (patientId: string, caseDetail: CaseDetail) => (
          <div
            className="admin-cases__patient-id"
            style={{ display: "flex", alignItems: "center" }}
          >
            {patientId}
            {/* {caseDetail.isUrgent && (
              <span
                style={{
                  width: 9,
                  height: 9,
                  backgroundColor: "red",
                  borderRadius: "50%",
                  marginLeft: 6,
                  animation: "blink 2s infinite",
                }}
              />
            )} */}
          </div>
        ),
        fixed: screenWidth > 900 ? "left" : undefined,
        onCell: getCustomCellStyles(100),
      },
      {
        title: "Patient Name",
        dataIndex: "patientName",
        key: "patientName",
        fixed: screenWidth > 900 ? "left" : undefined,
        width: 150,
        ellipsis: true,
        onCell: getCustomCellStyles(150),
      },
      {
        title: "Study",
        dataIndex: "scanTypeName",
        key: "scanTypeName",
        render: (_: string, record: CaseDetail) => (
          <span>{record?.getStudyName()}</span>
        ),
        width: 120,
        ellipsis: true,
        onCell: getCustomCellStyles(120),
      },
      ...(adminType === AdminTypeEnum.SCAN_CENTER_ADMIN
        ? [
            {
              title: "Referring Doctor",
              dataIndex: "referringDoctor",
              key: "referringDoctor",
              render: (text: string) => (
                <span className="admin-cases__referring-doctor">
                  {text || "-"}
                </span>
              ),
              width: 150,
              ellipsis: true,
              onCell: getCustomCellStyles(150),
            },
          ]
        : []),
      ...(adminFeatures &&
      adminFeatures?.includes(AdminFeatureEnum.CUSTOMISED_COLUMNS)
        ? [
            {
              title: "Patient History",
              dataIndex: "parsedPatientHistory",
              key: "parsedPatientHistory",
              width: 150,
              ellipsis: true,
              onCell: getCustomCellStyles(150),
            },
          ]
        : []),
      {
        title: "Scan Center",
        dataIndex: "scanCenterName",
        key: "scanCenterName",
        ellipsis: true,
        width: 120,
        onCell: getCustomCellStyles(120),
      },
      {
        title: "Date Uploaded",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
        ellipsis: true,
        render: (date: string) => (
          <span>
            {date ? (
              <span>
                {moment(date).format("DD-MMM-YY")}{" "}
                {moment(date).format("hh:mm A")}
              </span>
            ) : (
              "NA"
            )}
          </span>
        ),
        width: 150,
        onCell: getCustomCellStyles(150),
      },
      {
        title: "Assigned Doctor",
        dataIndex: "assignedToDoctorName",
        key: "assignedToDoctorName",
        ellipsis: true,
        render: (doctorName: string) => (
          <span className="admin-cases__assigned-doctor">
            {doctorName || "NA"}
          </span>
        ),
        width: 130,
        onCell: getCustomCellStyles(130),
      },
      {
        title: "Due Time",
        dataIndex: "dueDate",
        key: "dueDate",
        ellipsis: true,
        render: (time: string, caseDetail: CaseDetail) =>
          caseDetail?.status !== CaseStatusEnum.REPORTED ? time : null,
        width: 100,
        onCell: getCustomCellStyles(100),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 100,
        ellipsis: true,
        onCell: getCustomCellStyles(100),
        sorter: (a: any, b: any) => a.status.localeCompare(b.status),
        render: (status: string, caseDetail: CaseDetail) => {
          return (
            <div
              className="admin-cases__status-wrapper"
              onClick={stopPropogateEvent}
            >
              <span className={`admin-cases__status ${status}`}>
                {formatStatus(status)}
              </span>
            </div>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "id",
        render: (_: string, caseDetail: CaseDetail) => {
          const showGenerateReport =
            caseDetail?.status === CaseStatusEnum.TO_BE_APPROVED ||
            (caseDetail?.status === CaseStatusEnum.ASSIGNED &&
              me?.id === caseDetail?.assignedToAdminId);
          return (
            <div onClick={stopPropogateEvent}>
              {caseDetail?.status !== CaseStatusEnum.VIEW && (
                <Tooltip title="Assign Case">
                  <Popover
                    ref={assignmentPopoverRef}
                    destroyTooltipOnHide
                    overlayClassName="admin-cases__assignment-popover"
                    placement="bottomLeft"
                    content={
                      <div>
                        <CaseAssignmentForm
                          caseDetail={caseDetail}
                          onSuccess={handleAssignmentSuccess}
                        />
                      </div>
                    }
                    title="Assign case"
                    trigger="click"
                  >
                    <Button icon={<UserAddOutlined />} className="ml-2" />
                  </Popover>
                </Tooltip>
              )}
              {caseDetail?.status !== CaseStatusEnum.REPORTED && (
                <Tooltip title="Edit Case">
                  <Button
                    className="ml-2"
                    icon={<EditOutlined />}
                    onClick={handleEditCase(caseDetail)}
                  />
                </Tooltip>
              )}
              {showGenerateReport && (
                <Tooltip title="Generate Report">
                  <Button
                    icon={<FormOutlined />}
                    className="ml-2"
                    onClick={() => {
                      setActiveCase(caseDetail);
                      handleToggleReportForm();
                    }}
                  />
                </Tooltip>
              )}
              {caseDetail?.status === CaseStatusEnum.REPORTED && (
                <Tooltip title="Download Report">
                  <Popover
                    destroyTooltipOnHide
                    overlayClassName="admin-cases__assignment-popover"
                    placement="bottomLeft"
                    content={
                      <div>
                        <Menu mode="vertical" className="border-none">
                          <Menu.Item
                            className="navbar-item m-0"
                            key="with_letterhead"
                            onClick={() => {
                              handleDownloadReport(
                                caseDetail,
                                DownloadFileTypeEnum.PDF,
                                true
                              );
                            }}
                          >
                            Download with Letterhead
                          </Menu.Item>
                          <Menu.Item
                            className="navbar-item m-0"
                            key="without_letterhead"
                            onClick={() => {
                              handleDownloadReport(
                                caseDetail,
                                DownloadFileTypeEnum.PDF,
                                false
                              );
                            }}
                          >
                            Download without Letterhead
                          </Menu.Item>
                          <Menu.Item
                            className="navbar-item m-0"
                            key="as_word"
                            onClick={() => {
                              handleDownloadReport(
                                caseDetail,
                                DownloadFileTypeEnum.WORD,
                                false
                              );
                            }}
                          >
                            Download as Word
                          </Menu.Item>
                        </Menu>
                      </div>
                    }
                    title="Download Report"
                    trigger="click"
                  >
                    <Button
                      loading={
                        activeCase?.id === caseDetail?.id && downloadLoading
                      }
                      icon={<DownloadOutlined />}
                      className="ml-2"
                    />
                  </Popover>
                </Tooltip>
              )}
              <Tooltip title="Download Case">
                <Button
                  loading={
                    activeCase?.id === caseDetail?.id && downloadZipLoading
                  }
                  icon={<CloudDownloadOutlined />}
                  className="ml-2"
                  onClick={() => {
                    handleDownloadDetails(caseDetail);
                  }}
                />
              </Tooltip>
              {adminFeatures?.includes(AdminFeatureEnum.DIGITAL_SHARING) &&
                !adminFeatures?.includes(AdminFeatureEnum.WHATSAPP_SHARING) && (
                  <Tooltip title="Copy Link">
                    <Button
                      onClick={() => {
                        Notification({
                          message: "Copied",
                          type: NotificationTypes.SUCCESS,
                        });
                        handleCaseShareLink(caseDetail);
                      }}
                      icon={<CopyOutlined />}
                      className="ml-2"
                    />
                  </Tooltip>
                )}
              {adminFeatures?.includes(AdminFeatureEnum.DIGITAL_SHARING) &&
                adminFeatures?.includes(AdminFeatureEnum.WHATSAPP_SHARING) && (
                  <>
                    <Tooltip title="Share Link">
                      <Popover
                        destroyTooltipOnHide
                        overlayClassName="admin-cases__share-popover"
                        placement="bottomLeft"
                        content={
                          <div>
                            <Menu mode="vertical" className="border-none">
                              <Menu.Item
                                className="navbar-item m-0"
                                key="with_letterhead"
                                onClick={() => {
                                  Notification({
                                    message: "Copied",
                                    type: NotificationTypes.SUCCESS,
                                  });
                                  handleCaseShareLink(caseDetail);
                                }}
                              >
                                <CopyOutlined />
                                Copy Sharing Link
                              </Menu.Item>
                            </Menu>
                            <div>
                              <CaseSharing caseDetail={caseDetail} />
                            </div>
                          </div>
                        }
                        title="Share Case Link"
                        trigger="click"
                      >
                        <Button icon={<ShareAltOutlined />} className="ml-2" />
                      </Popover>
                    </Tooltip>
                  </>
                )}
              <Tooltip title="More Case Details">
                <Button
                  icon={<EllipsisOutlined />}
                  className="ml-2"
                  onClick={(event: any) => {
                    setActiveCase(caseDetail);
                    setShowCaseOverview(true);
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ]);
  }, [me, adminType, caseDetails]);

  const updatedColumns = columns.map((col: any, index: any) => ({
    ...col,
    title: (
      <div className="resizable-header">
        {col.title}
        <div
          className="resizer"
          onMouseDown={(e) => handleMouseDown(e, index)}
        />
      </div>
    ),
  }));

  return (
    <div className="admin-cases">
      <AppCard>
        <Row>
          <Col span={12}>
            <h2 className="mt-2">Cases</h2>
          </Col>
        </Row>
        <CaseFilterForm isAdmin formValues={formValues} />
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <>
            <Table
              scroll={{ x: "max-content", y: `calc(100vh - 375px)` }}
              dataSource={caseDetails}
              rowClassName="cursor-pointer"
              onRow={handleRowChange}
              columns={updatedColumns}
              pagination={{
                current: paginationMeta?.currentPage,
                pageSize: paginationMeta?.perPage,
                total: paginationMeta?.totalCount,
                showSizeChanger: true,
                pageSizeOptions: ["10", "15", "20", "50"],
              }}
              onChange={handlePageChange}
            />
          </>
        )}
        {caseDetails.length > 0 && <TagLegend />}
      </AppCard>
      <ContextMenu
        visible={menuVisible}
        position={menuPosition}
        selectedRow={selectedRow}
        onClose={() => setMenuVisible(false)}
        onUpdate={handleUpdateTags}
      />
      <Modal
        destroyOnClose
        visible={showCaseOverview}
        className="primary-modal"
        onCancel={handleCloseCaseOverview}
        footer={null}
        maskClosable={false}
      >
        {activeCase?.id && (
          <CaseOverview
            isAdmin
            activeCaseId={activeCase?.id}
            onCancel={handleCloseCaseOverview}
            onUpdateSuccess={handleCaseUpdateSuccess}
          />
        )}
      </Modal>
      <Modal
        destroyOnClose
        visible={showCaseForm}
        className="primary-modal"
        onCancel={handleToggleCaseForm}
        footer={null}
        maskClosable={false}
      >
        {activeCase && (
          <CaseForm
            caseDetailId={activeCase?.id}
            onSuccess={handleUploadCaseSuccess}
            onClose={() => setShowCaseForm(false)}
          />
        )}
      </Modal>
      <Drawer
        destroyOnClose
        title={null}
        width="70%"
        onClose={handleToggleReportForm}
        visible={showReportForm}
        maskClosable={false}
        footer={null}
      >
        {activeCase?.id && (
          <ReportForm
            caseDetails={activeCase}
            caseDetailId={activeCase.id}
            onPublish={() => handleFetchCases(formValues)}
          />
        )}
      </Drawer>
    </div>
  );
}

export default CaseContainer(AdminCases);
