import React, { useEffect, useMemo, useRef, useState } from "react";
import "./reportTemplateList.scss";
import { ReportTemplate } from "../../../models/ReportTemplate/reportTemplate.model";
import ReportTemplateService from "../../../services/ReportTemplate/reportTemplate.service";
import AppCard from "../../../shared/components/AppCard";
import { Button, Input, Modal, Table, Tooltip } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import ReportTemplateDetail from "../ReportTemplateDetail";
import ReportTemplateForm from "../ReportTemplateForm";
import AppLoader from "../../../shared/components/AppLoader";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { UserRoleEnum } from "../../../enums/userRole.enum";

interface ReportTemplateListProps extends AuthReducerProps {}

function ReportTemplateList({ userRole }: ReportTemplateListProps) {
  const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>([]);

  const [activeTemplate, setActiveTemplate] = useState(new ReportTemplate());

  const [loading, setLoading] = useState(false);

  const [showTemplateForm, setShowTemplateForm] = useState(false);

  const [showTemplateDetail, setShowTemplateDetail] = useState(false);

  const debounceTimerRef: any = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");

  const handleReportFormSuccess = (reportTemplate: ReportTemplate) => {
    const reportTemplateIndex = reportTemplates.findIndex(
      (template) => template.id === reportTemplate.id
    );
    if (reportTemplateIndex >= 0) {
      reportTemplates[reportTemplateIndex] = reportTemplate;
    } else {
      reportTemplates.unshift(reportTemplate);
    }
    setReportTemplates([...reportTemplates]);
    handleToggleTemplateForm();
  };

  const handleToggleTemplateForm = () => setShowTemplateForm(!showTemplateForm);

  const handleToggleTemplateDetail = () =>
    setShowTemplateDetail(!showTemplateDetail);

  const handleAddTemplate = () => {
    setActiveTemplate(new ReportTemplate());
    handleToggleTemplateForm();
  };

  const handleEditTemplate = (reportTemplate: ReportTemplate) => () => {
    setActiveTemplate(reportTemplate);
    handleToggleTemplateForm();
  };

  const fetchReportTemplates = (searchText: string) => {
    setLoading(true);
    ReportTemplateService.fetchReportTemplates(
      searchText,
      0,
      (reportTemplates: ReportTemplate[]) => {
        setReportTemplates(reportTemplates);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchReportTemplates("");
  }, []);

  const columns = [
    {
      title: "Template name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Scan Type",
      dataIndex: "scanTypeName",
      key: "scanTypeName",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: number, template: ReportTemplate) => {
        const isNew =
          template?.createdByAdminId && userRole === UserRoleEnum.DOCTOR;
        return (
          <Tooltip
            title={isNew ? "Create new using this template" : "Edit Template"}
          >
            <Button
              type="primary"
              icon={isNew ? <PlusOutlined /> : <EditOutlined />}
              onClick={handleEditTemplate(template)}
            />
          </Tooltip>
        );
      },
    },
  ];

  const handleSearch = (value: string) => {
    fetchReportTemplates(value);
  };

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    clearTimeout(debounceTimerRef.current);
    debounceTimerRef.current = setTimeout(() => {
      handleSearch(searchTerm);
    }, 500);
  }, [searchTerm]);

  return (
    <div className="report-template-list">
      <AppCard>
        <h2>
          Report Templates
          <Button
            type="primary"
            className="float-right"
            onClick={handleAddTemplate}
          >
            <PlusOutlined />
            Add Template
          </Button>
        </h2>
        <div className="report-template-list__search-wrapper">
          <Input.Search
            className="report-template-list__search-input"
            placeholder="Search by Template name"
            onChange={handleInputChange}
            style={{ width: 300 }}
          />
        </div>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <>
            <Table dataSource={reportTemplates} columns={columns} />
          </>
        )}
        <Modal
          destroyOnClose
          visible={showTemplateDetail}
          className="primary-modal"
          onCancel={handleToggleTemplateDetail}
          footer={null}
          maskClosable={false}
        >
          {activeTemplate?.id && (
            <ReportTemplateDetail reportTemplate={activeTemplate} />
          )}
        </Modal>
        <Modal
          destroyOnClose
          visible={showTemplateForm}
          className="primary-modal"
          onCancel={handleToggleTemplateForm}
          footer={null}
          maskClosable={false}
        >
          <ReportTemplateForm
            reportTemplate={activeTemplate}
            onSuccess={handleReportFormSuccess}
          />
        </Modal>
      </AppCard>
    </div>
  );
}

export default AuthContainer(ReportTemplateList);
