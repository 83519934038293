const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,

  /* Admin Routes */
  ADMIN_LOGIN: "/admin/admins/sign_in",
  ADMIN_SIGN_OUT: "/admin/admins/sign_out",
  DOCTORS: "/admin/doctors",
  REFERRING_DOCTORS: "/admin/referring_doctors",
  ASSIGNMENT_DOCTORS: "/admin/assignment_doctor_list",
  ADMIN_TAGS: "/admin/tags",
  TAGS: "/tags",
  UPDATE_ADMIN_TAGS: "/admin/case_details/:caseDetailId/update_tags",
  UPDATE_DOCTOR_TAGS: "/doctor/case_details/:caseDetailId/update_tags",
  UPDATE_SCAN_CENTER_TAGS: "/scan_center/case_details/:caseDetailId/update_tags",
  ADMINS: "/admin/admins",
  SCAN_CENTERS: "/admin/scan_centers",
  ADMIN_CASES: "/admin/case_details",
  ADMIN_ASSIGN_TO_DOCTOR: "/admin/case_details/:caseDetailId/assign_to_doctor",
  ADMIN_PROVIDE_VIEW_ACCESS:
    "/admin/case_details/:caseDetailId/view/assign_to_doctor",
  ADMIN_SELF_ASSIGN: "/admin/case_details/:caseDetailId/self_assign",
  ADMIN_DOCTOR_SUMMARY: "/admin/doctors/:doctorId/summary",
  ADMIN_SCAN_CENTER_SUMMARY: "/admin/scan_centers/:scanCenterId/summary",
  CASE_VIEW_ACCESS: "/admin/case_details/:caseDetailId/views",
  ADMIN_CASE_UPDATE: "/admin/case_details/:caseDetailId",
  CASE_VIEW_ASSIGN: "/admin/case_details/:caseDetailId/views/assign_to_doctor",
  ADMIN_SCAN_TYPES: "/admin/scan_types",
  ADMIN_BODY_PARTS: "/admin/body_parts",
  ADMIN_PROFILE: "/admin/admin/profile",
  UPDATE_ADMIN_PROFILE: "/admin/admins",
  ADMIN_DICOM_DOWNLOAD: "/admin/dicom_data/:caseDetailId/download",
  ADMIN_DICOM_DATA: "/admin/dicom_data",
  ADMIN_PASSWORD: "/admin/admins/password",
  ADMIN_ATTACHMENT_PRESIGNED_URL:
  "/admin/case_details/:caseDetailId/case_attachment/signed_url",
  ADMIN_ATTACHMENT_DELETE:
  "/admin/case_details/:caseDetailId/case_attachment/delete",
  ADMIN_REPORT_DOWNLOAD: "/admin/dicom_data/:caseDetailId/download_report",

  /* Doctor Routes */
  DOCTOR_LOGIN: "/doctor/doctors/sign_in",
  DOCTOR_SIGN_OUT: "/doctor/doctors/sign_out",
  DOCTOR_SIGNUP: "admin/doctors",
  DOCTOR_CASES: "/doctor/case_details",
  DOCTOR_SELF_ASSIGN: "/doctor/case_details/:caseDetailId/self_assign",
  DOCTOR_REMOVE_ASSIGNMENT:
    "/doctor/case_details/:caseDetailId/remove_assignment",
  DOCTOR_PROFILE: "/doctor/doctor/profile",
  UPDATE_DOCTOR_PROFILE: "/doctor/doctor",
  DOCTOR_DICOM_DOWNLOAD: "/doctor/dicom_data/:caseDetailId/download",
  DOCTOR_DICOM_DATA: "/doctor/dicom_data",
  DOCTOR_PASSWORD: "/doctor/doctors/password",

  REFERRING_DOCTOR_SIGNUP: "admin/referring_doctors",

  /* Scan center Routes */
  SCAN_CENTER_LOGIN: "/scan_center/scan_centers/sign_in",
  SCAN_CENTER_SIGNUP: "/admin/scan_centers",
  SCAN_CENTER_SIGN_OUT: "/scan_center/scan_centers/sign_out",
  DICOM_DATA: "/scan_center/dicom_data",
  SCAN_CENTER_CASES: "/scan_center/case_details",
  SCAN_CENTER_PROFILE: "/scan_center/scan_center/profile",
  UPDATE_SCAN_CENTER_PROFILE: "/scan_center/scan_center",
  SCAN_CENTER_DOWNLOAD_PDF:
    "/scan_center/reports/case_details/:caseDetailId/pdf",
  SCAN_CENTER_DOWNLOAD_WORD:
    "/scan_center/reports/case_details/:caseDetailId/word",
  SCAN_CENTER_PASSWORD: "/scan_center/scan_centers/password",
  SCAN_CENTER_PRESIGNED_URL:
    "/scan_center/case_details/:caseDetailId/signed_url",
  SCAN_CENTER_ATTACHMENT_PRESIGNED_URL:
    "/scan_center/case_details/:caseDetailId/case_attachment/signed_url",
  SCAN_CENTER_ATTACHMENT_DELETE:
    "/scan_center/case_details/:caseDetailId/case_attachment/delete",
  SCAN_CENTER_UPDATE_FILE:
    "/scan_center/case_details/:caseDetailId/update_file",
  /* Shared routes */
  USER_ROLE: "/user/role",
  USER_LOGIN: "/login",
  CASES: "/cases",
  SHOW_DICOM_DATA: "/dicom_data",
  SCAN_TYPES: "/scan_types",
  BODY_PARTS: "/body_parts",
  CASE_SHARE_LINK: "/shareable_links/:caseDetailId",
  CASE_WHATSAPP_SHARE_LINK: "/shareable_links/:caseDetailId/whatsapp",
  GUEST_LOGIN: "/guest_login",
  REPORT_TEMPLATES: "/reports/templates",
  ADMIN_REPORTS: "/admin/case_details/:caseDetailId/reports",
  REPORTS: "/reports/reports",
  PUBLISH_REPORT: "/reports/reports/:reportId/publish",
  APPROVE_AND_PUBLISH_REPORT: "/admin/reports/:reportId/approve_and_publish",
  SEND_FOR_APPROVAL: "/doctor/case_details/:caseDetailId/send_for_approval",
  PREVIEW_REPORT: "/reports/case_details/:caseDetailId/reports/preview",
  APPROVAL_PREVIEW_REPORT: "/admin/case_details/:caseDetailId/reports/preview",
  DOWNLOAD_PDF: "/reports/case_details/:caseDetailId/reports/pdf",
  APPROVAL_DOWNLOAD_PDF: "/admin/case_details/:caseDetailId/reports/pdf",
  DOWNLOAD_WORD: "/reports/case_details/:caseDetailId/reports/word",
  APPROVAL_DOWNLOAD_WORD: "/admin/case_details/:caseDetailId/reports/word",
  ADMIN_CASE_THUMBNAILS: "/admin/dicom_data/:caseDetailId/thumbnails",
  DOCTOR_CASE_THUMBNAILS: "/doctor/dicom_data/:caseDetailId/thumbnails",
  ADMIN_CASE_DIRECTORY_FILES: "/admin/dicom_data/:caseDetailId/dicom_files",
  DOCTOR_CASE_DIRECTORY_FILES: "/doctor/dicom_data/:caseDetailId/dicom_files",
  SCAN_CENTER_BRANCHES: "/scan_center_branches",
};
export default ApiRoutes;
