import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { ScanCenter } from "../../models/ScanCenter/scanCenter.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { DicomData } from "../../models/DicomData/dicomData.model";

export default class SharedService {
  static showCaseDicom(
    caseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SHOW_DICOM_DATA + "/" + caseId;
    axiosInstance
      .get(API_URL)
      .then((res) => {
        const dicomData = deserialize(DicomData, res.data as any[]);
        onSuccess(dicomData);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static getCaseShareLink(
    adminCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CASE_SHARE_LINK.replace(
      ":caseDetailId",
      adminCaseId.toString()
    );
    axiosInstance
      .post(API_URL)
      .then((response) => {
        onSuccess(response.data["link"], response.data["token"]);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static sendWhatsappShareLink(
      caseDetailId: number,
      data: {
        mobile_number?: string,
        referring_doctor_id?: number
      },
      onSuccess: Function,
      onError: Function,
      onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CASE_WHATSAPP_SHARE_LINK.replace(
        ":caseDetailId",
        caseDetailId.toString()
    );
    axiosInstance
        .post(API_URL, data)
        .then((response) => {
          Notification({
            message: "Whatsapp Notification Sent!",
            type: NotificationTypes.SUCCESS,
          });
          onSuccess(true);
        })
        .catch((error) => {
          Notification({
            message: "Whatsapp Notification Failed.",
            type: NotificationTypes.ERROR,
          });
          onError();
        })
        .finally(() => {
          onFinal();
        });
  }

  static guestLogin(
    caseID: number,
    key: string, 
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const paramsJSON = {
      id: caseID,
      key: key
    };
    axiosInstance
      .post(ApiRoutes.GUEST_LOGIN, paramsJSON)
      .then((response) => {
        onSuccess(response.data["jwt_token"]);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}
