import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Tag } from "../../models/Tag/tag.model";
import { UserRoleEnum } from "../../enums/userRole.enum";

export default class TagService {
    static fetchAdminTags(
      userRole: UserRoleEnum,
      onSuccess: Function,
      onError: Function,
      onFinal: () => void
    ) {
        axiosInstance
          .get(userRole === UserRoleEnum.ADMIN ? ApiRoutes.ADMIN_TAGS : ApiRoutes.TAGS)
          .then((response) => {
            const tags = deserialize(Tag, response.data);
            onSuccess(tags);
          })
          .catch((error) => {
            onError();
          })
          .finally(() => {
            onFinal();
          });
    }

    static updateAdminTags(
        caseId: number,
        userRole: UserRoleEnum,
        tagIds: (number | undefined)[],
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
      let apiRoutes;

      if (userRole === UserRoleEnum.ADMIN) {
        apiRoutes = ApiRoutes.UPDATE_ADMIN_TAGS;
      } else if (userRole === UserRoleEnum.DOCTOR) {
        apiRoutes = ApiRoutes.UPDATE_DOCTOR_TAGS;
      } else {
        apiRoutes = ApiRoutes.UPDATE_SCAN_CENTER_TAGS;
      }
        axiosInstance
          .put(apiRoutes.replace(':caseDetailId', caseId.toString()), {
            tag_ids: tagIds
          })
          .then((response) => {
            Notification({
                message: "Tag updated",
                type: NotificationTypes.SUCCESS,
              });
            const tags = deserialize(Tag, response.data);
            onSuccess(tags);
          })
          .catch((error) => {
            onError();
          })
          .finally(() => {
            onFinal();
          });
    }
}
