import {
  AUTHENTICATED,
  SET_USER,
  SET_USER_ROLE,
  UNAUTHENTICATED,
} from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import {
  SetAuthenticatedAction,
  SetUnauthenticatedAction,
  SetUserAction,
  SetUserRoleAction,
} from "../actions/authActions";
import { UserRoleEnum } from "../../enums/userRole.enum";
import { Admin } from "../../models/Admin/admin.model";
import { ScanCenter } from "../../models/ScanCenter/scanCenter.model";
import { Doctor } from "../../models/Doctor/doctor.model";
import { AdminTypeEnum } from "../../enums/AdminType.enum";

export interface AuthState {
  authenticated: boolean;
  userRole?: UserRoleEnum;
  adminType?: AdminTypeEnum;
  user?: Admin | ScanCenter | Doctor;
}

export interface AuthReducerProps extends AuthState {
  setAuthenticated: () => SetAuthenticatedAction;
  setUnAuthenticated: () => SetUnauthenticatedAction;
  setUserRole: (
    userRole: string,
    adminType?: AdminTypeEnum
  ) => SetUserRoleAction;
}

const initState: AuthState = {
  authenticated: false,
  user: undefined,
  userRole: undefined,
};

const authReducer = CreateReducer(initState, {
  [AUTHENTICATED](state: AuthState, action: SetAuthenticatedAction): AuthState {
    const { authenticated, user } = action?.payload;
    return {
      ...state,
      authenticated,
      user,
    };
  },
  [UNAUTHENTICATED](
    state: AuthState,
    action: SetUnauthenticatedAction
  ): AuthState {
    const { authenticated } = action?.payload;
    return { ...state, authenticated };
  },
  [SET_USER_ROLE](state: AuthState, action: SetUserRoleAction): AuthState {
    const { userRole, adminType } = action?.payload;
    return { ...state, userRole, adminType };
  },
  [SET_USER](state: AuthState, action: SetUserAction): AuthState {
    const { user } = action?.payload;
    return { ...state, user };
  },
});

export default authReducer;
