import React from "react";
import AuthRouter from "../authRouters";
import logo from "../../../assets/images/telerad-logo.png";
import "./authWrapper.scss";

const AuthWrapper = () => {
  const { origin } = window.location;
  const isConrad = origin.includes("conrad.radiolinq.com");
  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper__logo-section">
        <div className="auth-wrapper__logo-section-content">
          <h1 className="auth-wrapper__logo">
            {isConrad ? "CONRAD" : "RadiolinQ"}
          </h1>
          <h5 className="auth-wrapper__logo-subtext">
            {isConrad ? "TELERADIOLOGY" : "Imaging Anytime Anywhere"}
          </h5>
          {isConrad && (
            <h6 className="auth-wrapper__powered-by-text">
              <span>Powered by</span>
              <span>RadiolinQ</span>
            </h6>
          )}
        </div>
      </div>
      <div className="auth-wrapper__auth-section">
        <div>
          <div className="auth-wrapper__logo-section-mobile">
            <h1 className="auth-wrapper__logo-mobile">
              {isConrad ? "CONRAD" : "RadiolinQ"}
            </h1>
            <h5 className="auth-wrapper__logo-subtext-mobile">
              {isConrad ? "TELERADIOLOGY" : "Imaging Anytime Anywhere"}
            </h5>
          </div>
          <AuthRouter />
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
