import React, { useEffect, useRef, useState } from "react";
import "./caseOverview.scss";
import { Button, Col, Modal, Popover, Row } from "antd";
import { generatePath, Link, useHistory } from "react-router-dom";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import AdminCaseService from "../../../services/Case/AdminCase/adminCase.service";
import AppLoader from "../AppLoader";
import AuthContainer from "../../../store/container/AuthContainer";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import ReportService from "../../../services/Report/report.service";
import DoctorCaseService from "../../../services/Case/DoctorCase/doctorCase.service";
import { hasDoctorAccessToCase } from "../../utils/accessUtils";
import ScanCenterCaseService from "../../../services/Case/ScanCenterCase/scanCenterCase.service";
import CaseAssignmentForm from "../../../views/Cases/AdminCases/CaseAssignmentForm";

interface CaseOverviewProps extends AuthReducerProps {
  activeCaseId: number;
  onCancel: () => void;
  isAdmin?: boolean;
  onUpdateSuccess?: (caseDetail: CaseDetail) => void;
}

function CaseOverview({
  activeCaseId,
  onCancel,
  onUpdateSuccess,
  userRole,
  isAdmin,
}: CaseOverviewProps) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [activeCase, setActiveCase] = useState<CaseDetail>();

  const [showCaseEdit, setShowCaseEdit] = useState(false);

  const [pdfLoading, setPdfLoading] = useState(false);

  const [wordLoading, setWordLoading] = useState(false);

  const editCasePopoverRef = useRef<any>(null);

  const handleUpdateSuccess = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    onUpdateSuccess && onUpdateSuccess(caseDetail);
    handleCloseEditPopover();
  };

  const handleCloseEditPopover = () => {
    if (editCasePopoverRef?.current && editCasePopoverRef.current) {
      editCasePopoverRef.current.setPopupVisible(false);
    }
  };

  useEffect(() => {
    if (activeCaseId) {
      const showCase =
        userRole === UserRoleEnum.ADMIN
          ? AdminCaseService.showAdminCase
          : userRole === UserRoleEnum.DOCTOR
          ? DoctorCaseService.showDoctorCase
          : ScanCenterCaseService.showScanCenterCase;
      setLoading(true);
      showCase(
        activeCaseId,
        (caseDetail: CaseDetail) => {
          setActiveCase(caseDetail);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, [activeCaseId]);

  return (
    <div className="case-overview">
      <h2 className="text-primary">Patient details</h2>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Row gutter={[20, 20]} align="top">
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
            <div className="case-overview__label">Patient Name</div>
            <div className="case-overview__value">
              {activeCase?.patientName}
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
            <div className="case-overview__label">Patient ID</div>
            <div className="case-overview__value">{activeCase?.patientId}</div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
            <div className="case-overview__label">Patient Age</div>
            <div className="case-overview__value">{activeCase?.age}</div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
            <div className="case-overview__label">Gender</div>
            <div className="case-overview__value">{activeCase?.gender}</div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
            <div className="case-overview__label">Scan Type</div>
            <div className="case-overview__value">
              {activeCase?.scanTypeName}
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
            <div className="case-overview__label">Body parts</div>
            <div className="case-overview__value">
              {activeCase?.caseBodyParts &&
                activeCase.caseBodyParts
                  .map((bodyPart) => bodyPart?.bodyPartName)
                  .join(", ")}
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <div className="case-overview__label">Contrast</div>
            <div className="case-overview__value text-capitalize">
              {activeCase?.contrast?.toString()}
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <div className="case-overview__label">Study Description</div>
            <div className="case-overview__value">
              {activeCase?.studyDescription}
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
            <div className="case-overview__label">Referring Doctor</div>
            <div className="case-overview__value">
              {activeCase?.referringDoctor}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div className="case-overview__label">Patient History</div>
            <div className="case-overview__value">
              {activeCase?.parsedPatientHistory}
            </div>
          </Col>
          <Col span={24}>
            <div className="case-overview__label">Case Attachments</div>
          </Col>
          {activeCase?.caseAttachments?.map((attachment) => (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={6}
              xxl={6}
              className="case-overview__img-wrapper"
              key={attachment?.imageUrl}
            >
              <a href={attachment.imageSignedUrl} target="_">
                <img
                  src={attachment.imageSignedUrl}
                  className="case-overview__img"
                  alt="Attachment"
                />
              </a>
            </Col>
          ))}
          <Col span={24} className="right-align">
            {(userRole === UserRoleEnum.ADMIN ||
              userRole === UserRoleEnum.SCAN_CENTER ||
              hasDoctorAccessToCase(userRole, activeCase)) && (
              <Button
                type="primary"
                onClick={() =>
                  window.open(
                    generatePath(AppRoutes.CASE_DETAIL, {
                      caseId: activeCaseId,
                    }),
                    "_blank"
                  )
                }
              >
                View Medical Images
              </Button>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default AuthContainer(CaseOverview);
