import React, { useCallback, useEffect, useState, useRef } from "react";
import "./caseFilterForm.scss";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { Form, Formik } from "formik";
import { Button, Col, Row, Switch, Tooltip } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import DropdownField from "../DropdownField";
import DatePickerField from "../DatePickerField";
import ScanCenterService from "../../../services/ScanCenter/scanCenter.service";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../utils/dropdownUtils";
import DoctorService from "../../../services/Doctor/doctor.service";
import { Doctor } from "../../../models/Doctor/doctor.model";
import ScanTypeService from "../../../services/ScanType/scanType.service";
import { ScanType } from "../../../models/ScanType/scanType.model";
import BodyPartService from "../../../services/BodyPart/bodyPart.service";
import { BodyPart } from "../../../models/BodyPart/bodyPart.model";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import { ScanCenterBranch } from "../../../models/ScanCenterBranch/scanCenterBranch.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import InputField from "../InputField";
import { useHistory, useLocation } from "react-router-dom";
import { AdminTypeEnum } from "../../../enums/AdminType.enum";
import TagService from "../../../services/TagService/tag.service";
import { Tag } from "../../../models/Tag/tag.model";
import { UserRoleEnum } from "../../../enums/userRole.enum";

function debounce(func: Function, delay: number) {
  let timer: NodeJS.Timeout;
  return function (...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

interface CaseFilterFormProps extends AuthReducerProps {
  formValues: CaseFilterParams;
  onSubmit: (params: CaseFilterParams) => void;
  onChange: (params: CaseFilterParams) => void;
  onReset: () => void;
  isAdmin?: boolean;
  isDoctor?: boolean;
  isScanCenter?: boolean;
}

const useFilterPersistence = (initialValues: CaseFilterParams) => {
  const history = useHistory();
  const location = useLocation();

  // Parse URL parameters on initial load
  const getInitialFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    const urlFilters: Partial<CaseFilterParams> = {};

    // Map of parameter names to their parsing logic
    const paramParsers: Record<string, (value: string) => any> = {
      patientId: (v) => v,
      patientName: (v) => v,
      referringDoctor: (v) => v,
      scanCenterId: (v) => (v ? Number(v) : undefined),
      doctorId: (v) => (v ? Number(v) : undefined),
      tagId: (v) => (v ? Number(v) : undefined),
      scanTypeId: (v) => (v ? Number(v) : undefined),
      bodyPartId: (v) => (v ? Number(v) : undefined),
      status: (v) => v,
      fromDate: (v) => v,
      toDate: (v) => v,
    };

    // Parse each parameter
    Object.keys(paramParsers).forEach((key) => {
      const value = searchParams.get(key);
      if (value !== null) {
        urlFilters[key as keyof CaseFilterParams] = paramParsers[key](value);
      }
    });

    // Merge URL parameters with initial values
    return { ...initialValues, ...urlFilters };
  };

  // Update URL with current filter values
  const updateUrlParams = useCallback(
    (values: CaseFilterParams) => {
      const searchParams = new URLSearchParams();

      // Add non-empty filter values to URL
      (Object.keys(values) as Array<keyof CaseFilterParams>).forEach((key) => {
        const value = values[key];
        if (value !== undefined && value !== null && value !== "") {
          searchParams.set(key, String(value));
        }
      });

      // Update URL without page reload
      history.replace(`${location.pathname}?${searchParams.toString()}`);
    },
    [location.pathname, history]
  );

  return { getInitialFilters, updateUrlParams };
};

function CaseFilterForm({
  isAdmin,
  isDoctor,
  isScanCenter,
  formValues,
  user,
  userRole,
  onReset,
  onSubmit,
  onChange,
  adminType,
}: CaseFilterFormProps) {
  const { getInitialFilters, updateUrlParams } = useFilterPersistence(
    formValues
  );

  const [showFilters, setShowFilters] = useState(window.innerWidth > 768);

  const [scanTypeOptions, setScanTypeOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [bodyPartOptions, setBodyPartOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [scanCenterBranchOptions, setScanCenterBranchOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [selectedScanCenterId, setSelectedScanCenterId] = useState<number>();

  const [doctorOptions, setDoctorOptions] = useState<IDropdownOptions[]>([]);

  const [tagOptions, setTagOptions] = useState<IDropdownOptions[]>([]);

  const [scanCenterOptions, setScanCenterOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [patientNameSearch, setPatientNameSearch] = useState("");

  const [patientIdSearch, setPatientIdSearch] = useState("");

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const toggleFilterForm = () => {
    setShowFilters(!showFilters);
  };

  const doctorStatusOptions = [
    { label: "Assigned", value: CaseStatusEnum.ASSIGNED },
    { label: "Unassigned", value: CaseStatusEnum.UNASSIGNED },
    { label: "Reported", value: CaseStatusEnum.REPORTED },
  ];

  const adminStatusOptions = [
    { label: "Assigned", value: CaseStatusEnum.ASSIGNED },
    { label: "Unassigned", value: CaseStatusEnum.UNASSIGNED },
    { label: "Reported", value: CaseStatusEnum.REPORTED },
    { label: "To be approved", value: CaseStatusEnum.TO_BE_APPROVED },
    { label: "Report Pending", value: CaseStatusEnum.REPORT_PENDING },
  ];

  const otherStatusOptions = [
    { label: "Reported", value: CaseStatusEnum.REPORTED },
    { label: "Report Pending", value: CaseStatusEnum.REPORT_PENDING },
  ];

  const handleFetchScanCenterOptions = (scanCenterId: number) => {
    ScanTypeService.fetchScanCenterBranches(
      scanCenterId,
      (scanCenterBranches: ScanCenterBranch[]) => {
        setScanCenterBranchOptions(
          getDropdownOptions(scanCenterBranches, "name", "id")
        );
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    if (isAdmin) {
      ScanCenterService.fetchScanCenters(
        (scanCenters: ScanCenter[]) => {
          setScanCenterOptions(getDropdownOptions(scanCenters, "name", "id"));
        },
        () => {},
        () => {}
      );
      DoctorService.fetchDoctors(
        (doctors: Doctor[]) => {
          setDoctorOptions(getDropdownOptions(doctors, "doctorName", "id"));
        },
        () => {},
        () => {}
      );
    }
    ScanTypeService.fetchScanTypes(
      (scanTypes: ScanType[]) => {
        setScanTypeOptions(getDropdownOptions(scanTypes, "name", "id"));
      },
      () => {},
      () => {}
    );
    const scanCenterId = isScanCenter
      ? Number(user?.id)
      : Number(selectedScanCenterId);
    if (isScanCenter) {
      handleFetchScanCenterOptions(Number(user?.id));
    }

    TagService.fetchAdminTags(
      userRole as UserRoleEnum,
      (tags: Tag[]) => {
        setTagOptions(getDropdownOptions(tags, "formattedName", "id"));
      },
      () => {},
      () => {}
    );

    BodyPartService.fetchBodyParts(
      (bodyParts: BodyPart[]) => {
        setBodyPartOptions(getDropdownOptions(bodyParts, "name", "id"));
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    if (selectedScanCenterId)
      handleFetchScanCenterOptions(selectedScanCenterId);
  }, [selectedScanCenterId]);

  return (
    <div className="case-filter-form">
      <Tooltip title={showFilters ? "Hide Filters" : "Show Filters"}>
        <Button
          className="case-filter-form__toggle-button"
          type="primary"
          onClick={toggleFilterForm}
          icon={<FilterOutlined />}
        />
      </Tooltip>
      <Formik
        initialValues={getInitialFilters()} // Use URL-aware initial values
        onSubmit={(values, actions) => {
          // Update URL params before submitting
          updateUrlParams(values);
          onSubmit(values);
        }}
        enableReinitialize
        validationSchema={null}
      >
        {({ values, setFieldValue }) => {
          const handleFieldChange = (fieldName: string, value: any) => {
            setFieldValue(fieldName, value); // Update the field value in Formik state

            // Update URL params on each field change
            updateUrlParams({ ...values, [fieldName]: value, page: 1 });

            if (fieldName === "patientName") {
              setPatientNameSearch(value);
            }

            if (fieldName === "patientId") {
              setPatientIdSearch(value);
            }

            // Trigger the onChange callback with the updated form values
            if (onChange) {
              onChange({ ...values, [fieldName]: value });
            }
          };
          return (
            <Form>
              <Row gutter={[20, 10]} align="middle">
                {showFilters && (
                  <>
                    <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                      <InputField
                        allowClear
                        placeholder="Patient ID"
                        title="Patient ID"
                        name="patientId"
                        type={"string"}
                        value={values.patientId}
                        onChange={(event: any) => {
                          handleFieldChange("patientId", event.target.value);
                        }}
                      />
                    </Col>
                    <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                      <InputField
                        allowClear
                        placeholder="Patient Name"
                        title="Patient Name"
                        name="patientName"
                        type={"string"}
                        value={values.patientName}
                        onChange={(event: any) => {
                          handleFieldChange("patientName", event.target.value);
                        }}
                      />
                    </Col>
                    <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                      <DropdownField
                        allowClear
                        placeHolder="Scan Type"
                        showSearch
                        title="Scan Type"
                        name="scanTypeId"
                        setFieldValue={setFieldValue}
                        options={scanTypeOptions}
                        value={values.scanTypeId}
                        onChange={(value) => {
                          handleFieldChange("scanTypeId", value);
                        }}
                      />
                    </Col>
                    <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                      <DropdownField
                        allowClear
                        placeHolder="Body Part"
                        showSearch
                        title="Body Part"
                        name="bodyPartId"
                        setFieldValue={setFieldValue}
                        options={bodyPartOptions}
                        value={values.bodyPartId}
                        onChange={(value) => {
                          handleFieldChange("bodyPartId", value);
                        }}
                      />
                    </Col>
                    {(user?.adminType === AdminTypeEnum.SCAN_CENTER_ADMIN ||
                      user?.adminType ===
                        AdminTypeEnum.DIGITAL_SHARING_ADMIN) && (
                      <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                        <InputField
                          allowClear
                          placeholder="Referring Doctor"
                          title="Referring Doctor"
                          name="referringDoctor"
                          type={"string"}
                          value={values?.referringDoctor}
                          onChange={(event: any) => {
                            const { value } = event.target;
                            handleFieldChange("referringDoctor", value);
                          }}
                        />
                      </Col>
                    )}
                    {isAdmin && (
                      <React.Fragment>
                        <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                          <DropdownField
                            allowClear
                            placeHolder="Scan Center"
                            showSearch
                            title="Scan Center"
                            name="scanCenterId"
                            setFieldValue={setFieldValue}
                            options={scanCenterOptions}
                            value={values.scanCenterId}
                            onChange={(value) => {
                              setFieldValue("scanCenterId", value);
                              setFieldValue("scanCenterBranchId", undefined);
                              setScanCenterBranchOptions([]);
                              setSelectedScanCenterId(value);
                              handleFieldChange("scanCenterId", value);
                            }}
                          />
                        </Col>
                        {user?.adminType !==
                          AdminTypeEnum.DIGITAL_SHARING_ADMIN && (
                          <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                            <DropdownField
                              allowClear
                              placeHolder="Doctor"
                              showSearch
                              title="Doctor"
                              name="doctorId"
                              setFieldValue={setFieldValue}
                              options={doctorOptions}
                              value={values.doctorId}
                              onChange={(value) => {
                                handleFieldChange("doctorId", value);
                              }}
                            />
                          </Col>
                        )}
                      </React.Fragment>
                    )}
                    {user?.adminType !==
                      AdminTypeEnum.DIGITAL_SHARING_ADMIN && (
                      <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                        <DropdownField
                          allowClear
                          placeHolder="Select Status"
                          title="Status"
                          name="status"
                          setFieldValue={setFieldValue}
                          options={
                            isAdmin
                              ? adminStatusOptions
                              : isDoctor
                              ? doctorStatusOptions
                              : otherStatusOptions
                          }
                          value={values.status}
                          onChange={(value) => {
                            handleFieldChange("status", value);
                          }}
                        />
                      </Col>
                    )}
                    {user?.adminType !==
                      AdminTypeEnum.DIGITAL_SHARING_ADMIN && (
                      <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                        <DropdownField
                          allowClear
                          placeHolder="Select Tag"
                          showSearch
                          title="Tag"
                          name="tagId"
                          setFieldValue={setFieldValue}
                          options={tagOptions}
                          value={values.tagId}
                          onChange={(value) => {
                            handleFieldChange("tagId", value);
                          }}
                        />
                      </Col>
                    )}
                    <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                      <DatePickerField
                        title="From Date"
                        name="fromDate"
                        defaultValue={values.fromDate}
                        setFieldValue={setFieldValue}
                        onChange={(date, dateString) => {
                          const formattedDate = date
                            ? date.format("YYYY-MM-DD")
                            : null;
                          handleFieldChange("fromDate", formattedDate);
                        }}
                      />
                    </Col>
                    <Col span={4} xs={12} md={4} lg={4} xl={4} xxl={4}>
                      <DatePickerField
                        title="To Date"
                        name="toDate"
                        defaultValue={values.toDate}
                        setFieldValue={setFieldValue}
                        onChange={(date, dateString) => {
                          const formattedDate = date
                            ? date.format("YYYY-MM-DD")
                            : null;
                          handleFieldChange("toDate", formattedDate);
                        }}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AuthContainer(CaseFilterForm);
