import React, { useEffect, useState } from "react";
import AppCard from "../../../shared/components/AppCard";
import { Button, Table, Modal, Popconfirm, Tooltip } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import "./ReferringdoctorList.scss";
import { ReferringDoctor } from "../../../models/ReferringDoctor/referringDoctor.model";
import ReferringDoctorForm from "../ReferringDoctorForm";
import ReferringDoctorService from "../../../services/ReferringDoctor/referringDoctor.service";
import AppLoader from "../../../shared/components/AppLoader";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { generatePath, Link } from "react-router-dom";

interface ReferringDoctorListProps {}

function ReferringDoctorList(props: ReferringDoctorListProps) {
  const [doctors, setDoctors] = useState<ReferringDoctor[]>([]);

  const [showDoctorForm, setShowDoctorForm] = useState(false);

  const [activeDoctor, setActiveDoctor] = useState(new ReferringDoctor());

  const [loading, setLoading] = useState(false);

  const handleCloseDoctorForm = () => setShowDoctorForm(false);

  const handleAddDoctor = () => {
    setActiveDoctor(new ReferringDoctor());
    setShowDoctorForm(true);
  };

  const handleDeleteDoctor = (doctor: ReferringDoctor) => {
    // console.log(doctor);
  };

  const handleDoctorFormSuccess = (doctor: ReferringDoctor) => {
    setShowDoctorForm(false);
    handleFetchDoctors();
  };

  const handleFetchDoctors = () => {
    setLoading(true);
    ReferringDoctorService.fetchReferringDoctors(
      (doctors: ReferringDoctor[]) => {
        setDoctors(doctors);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchDoctors();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, doctor: ReferringDoctor) => {
        return (
          <div className="doctor-list_actions">
            <Tooltip title="Edit Referring Doctor">
              <EditOutlined
                onClick={() => {
                  setShowDoctorForm(true);
                  setActiveDoctor(doctor);
                }}
              />
            </Tooltip>
            {/* For future use */}
            {/* <Popconfirm
              title="Are you sure to delete this Doctor?"
              placement="left"
              onConfirm={() => {
                handleDeleteDoctor(doctor);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete Doctor">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
            <Tooltip title="View summary">
              <Link
                to={generatePath(AppRoutes.DOCTOR_SUMMARY, {
                  doctorId: doctor?.id,
                })}
              >
                <FileTextOutlined />
              </Link>
            </Tooltip> */}
            {/* For future use */}
          </div>
        );
      },
      width: 150,
    },
  ];

  return (
    <div className="doctor-list">
      <AppCard>
        <h2>
          Referring Doctors
          <Button
            type="primary"
            className="float-right"
            onClick={handleAddDoctor}
          >
            <PlusOutlined />
            Add Referring Doctor
          </Button>
        </h2>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table dataSource={doctors} columns={columns} />
        )}
      </AppCard>
      <Modal
        className="primary-modal"
        footer=""
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        visible={showDoctorForm}
        onCancel={handleCloseDoctorForm}
      >
        {activeDoctor && (
          <ReferringDoctorForm
            doctor={activeDoctor}
            onSuccess={handleDoctorFormSuccess}
          />
        )}
      </Modal>
    </div>
  );
}

export default ReferringDoctorList;
