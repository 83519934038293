import { serializable, alias, primitive } from "serializr";

export class PaginationMeta {
  @serializable(alias("current_page", primitive()))
  currentPage?: number;

  @serializable(alias("total_count", primitive()))
  totalCount?: number;

  @serializable(alias("total_pages", primitive()))
  totalPages?: number;

  @serializable(alias("per_page", primitive()))
  perPage?: number;
}
