import React, { useEffect, useState, useRef } from "react";
import "./scanCenterCases.scss";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import ScanCenterCaseService from "../../../services/Case/ScanCenterCase/scanCenterCase.service";
import moment from "moment";
import ContextMenu from "../../../shared/components/ContextMenu";
import { Tag } from "../../../models/Tag/tag.model";
import TagLegend from "../../../shared/components/TagLegend";
import {
  Button,
  Col,
  Input,
  Menu,
  Modal,
  Popover,
  Row,
  Switch,
  Table,
  TablePaginationConfig,
  Tooltip,
} from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import CaseOverview from "../../../shared/components/CaseOverview";
import {
  EditOutlined,
  PlusOutlined,
  DownloadOutlined,
  CopyOutlined,
  WhatsAppOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import CaseForm from "./CaseForm";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import ReportService from "../../../services/Report/report.service";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import { CaseBodyPart } from "../../../models/CaseBodyPart/caseBodyPart.model";
import CaseContainer from "../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../store/reducers/caseReducer";
import CaseFilterForm from "../../../shared/components/CaseFilterForm";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import SharedCaseService from "../../../services/Shared/shared.service";
import {
  copyCaseDetailsToClipboard,
  stopPropogateEvent,
} from "../../../shared/utils/eventUtils";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { PaginationMeta } from "../../../models/PaginationMeta/paginationMeta.model";
import { useHistory, useLocation } from "react-router-dom";
import {
  getCustomCellStyles,
  getInitialFiltersFromURL,
  formatStatus,
} from "../../../shared/utils/dataFormatConverter";
import ProfileService from "../../../services/Profile/profile.service";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import { ScanCenter } from "../../../models/ScanCenter/scanCenter.model";
import { AdminFeatureEnum } from "../../../enums/AdminFeature.enum";
import { AdminTypeEnum } from "../../../enums/AdminType.enum";
import { ColumnsType } from "antd/lib/table";

interface ScanCenterCasesProps extends CaseReducerProps {}

function ScanCenterCases({
  pendingCaseIds,
  completedCaseIds,
  uploadStartedCaseIds,
  newCaseDetail,
  updatedCaseDetail,
}: ScanCenterCasesProps) {
  const location = useLocation();

  const [caseDetails, setCaseDetails] = useState<CaseDetail[]>([]);

  const [me, setMe] = useState<ScanCenter>();

  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>(
    Object.assign(new PaginationMeta(), {
      currentPage: 1,
      totalCount: 0,
      totalPages: 0,
    })
  );

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [activeCase, setActiveCase] = useState<CaseDetail>();

  const [showCaseForm, setShowCaseForm] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [activePopoverId, setActivePopoverId] = useState<number | null>(null);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [menuVisible, setMenuVisible] = useState(false);

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const [selectedRow, setSelectedRow] = useState<any>(null);

  const menuRef = useRef<HTMLDivElement>(null);

  const [formValues, setFormValues] = useState<CaseFilterParams>(
    Object.assign(new CaseFilterParams(), {
      ...new CaseFilterParams(),
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      scanCenterBranchId: undefined,
      page: 1,
    })
  );

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const resizingColumnRef = useRef(null);

  const startXRef = useRef(0);

  const startWidthRef = useRef(0);

  const history = useHistory();

  const [showCaseOverview, setShowCaseOverview] = useState(false);

  const adminFeatures = me?.adminFeatures?.map(
    (adminFeature) => adminFeature.feature
  );

  const updateUrlParams = (values: CaseFilterParams) => {
    const searchParams = new URLSearchParams();
    // Add non-empty filter values to URL
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        searchParams.set(key, String(value));
      }
    });
    // Update URL without page reload
    history.replace(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleToggleCaseForm = () => setShowCaseForm(!showCaseForm);

  const handleCloseCaseOverview = () => setShowCaseOverview(false);

  const handleFetchCases = (formValues: CaseFilterParams) => {
    setLoading(true);
    ScanCenterCaseService.fetchScanCenterCases(
      formValues,
      (caseDetails: CaseDetail[], paginationMeta: PaginationMeta) => {
        setCaseDetails(caseDetails);
        setPaginationMeta(paginationMeta);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleRowChange = (caseDetail: CaseDetail, _: number | undefined) => {
    return {
      onClick: (event: any) => {
        setActiveCase(caseDetail);
        setShowCaseOverview(true);
      },
      onContextMenu: (event: React.MouseEvent) =>
        handleRowContextMenu(caseDetail, event),
    };
  };

  const handleUpdateTags = (caseId: number, tags: Tag[]) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseId
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex].tags = tags;
    }
    setCaseDetails([...caseDetails]);
  };

  const handlePageChange = (pagination: TablePaginationConfig) => {
    const values = Object.assign(new CaseFilterParams(), {
      ...formValues,
      page: pagination.current,
      perPage: pagination.pageSize,
    });
    updateUrlParams(values);
  };

  const handleUploadCaseSuccess = (caseDetail: CaseDetail) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseDetail.id
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex] = caseDetail;
    } else {
      caseDetails.unshift(caseDetail);
    }
    setCaseDetails([...caseDetails]);
  };

  const handleAddCase = () => {
    setActiveCase(new CaseDetail());
    handleToggleCaseForm();
  };

  const handleEditCase = (caseDetail: CaseDetail) => () => {
    setActiveCase(caseDetail);
    handleToggleCaseForm();
  };

  const handleSubmit = (values: CaseFilterParams) => {
    const formValues = Object.assign(new CaseFilterParams(), values);
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  // const handleOnChange = (updatedValues: CaseFilterParams) => {
  //   // Update the form values with new inputs
  //   const newFormValues = Object.assign(new CaseFilterParams(), updatedValues);
  //   setFormValues(newFormValues);

  //   // Clear any existing debounce timer
  //   if (debounceTimerRef.current) {
  //     clearTimeout(debounceTimerRef.current);
  //   }
  //   // Set a new debounce timer
  //   debounceTimerRef.current = setTimeout(() => {
  //     handleFetchCases(newFormValues, paginationMeta); // Call the fetch function with the updated form values
  //   }, 500); // 500ms debounce delay
  // };

  // Cleanup on component unmount to clear the debounce timer
  useEffect(() => {
    ProfileService.getProfile(
      UserRoleEnum.SCAN_CENTER,
      (scanCenter: ScanCenter) => {
        setMe(scanCenter);
      },
      () => {},
      () => {}
    );
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handleDownloadCase = (
    caseDetail: CaseDetail,
    fileType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => {
    if (caseDetail?.id) {
      setDownloadLoading(true);
      setActiveCase(caseDetail);
      ReportService.downloadScanCenterReport(
        fileType,
        caseDetail,
        withLetterHead,
        (reportUrl: string) => {
          window.open(reportUrl, "_blank");
        },
        () => {},
        () => {
          setDownloadLoading(false);
          setActiveCase(undefined);
        }
      );
    }
  };

  const handleCaseShareLink = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      SharedCaseService.getCaseShareLink(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          copyCaseDetailsToClipboard(caseDetail, targetUrl);
        },
        () => {},
        () => {}
      );
    }
  };

  const handleToggleUrgency = (
    isUrgent: boolean,
    caseDetail: CaseDetail,
    switchElement: EventTarget | null
  ) => {
    setActiveCase(caseDetail);
    caseDetail.isUrgent = isUrgent;
    ScanCenterCaseService.updateScanCenterCase(
      caseDetail,
      (updatedCaseDetail: CaseDetail) => {
        if (switchElement instanceof HTMLElement) {
          switchElement.style.backgroundColor = isUrgent ? "red" : "gray";
        }
        setActiveCase(updatedCaseDetail);
      },
      () => {
        if (switchElement instanceof HTMLElement) {
          switchElement.click();
          switchElement.style.backgroundColor = isUrgent ? "gray" : "red";
        }
      },
      () => {}
    );
  };

  useEffect(() => {
    if (newCaseDetail) {
      handleFetchCases(formValues);
      // caseDetails.unshift(newCaseDetail);
      // setCaseDetails([...caseDetails]);
    }
  }, [newCaseDetail]);

  useEffect(() => {
    if (updatedCaseDetail) {
      // Commented for future use
      // const caseIndex = caseDetails.findIndex(
      //   (caseDetail) => caseDetail.id === updatedCaseDetail.id
      // );
      // if (caseIndex >= 0) {
      //   caseDetails[caseIndex] = updatedCaseDetail;
      //   setCaseDetails([...caseDetails]);
      // }
      handleFetchCases(formValues);
    }
  }, [updatedCaseDetail]);

  const handleMouseDown = (e: any, index: any) => {
    resizingColumnRef.current = index;
    startXRef.current = e.clientX;
    startWidthRef.current = columns[index].width;

    const screenWidth = window.innerWidth;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: any) => {
    if (resizingColumnRef.current !== null) {
      const newWidth = startWidthRef.current + (e.clientX - startXRef.current);
      const newColumns = [...columns];
      newColumns[resizingColumnRef.current].width = Math.max(newWidth, 100);
      setColumns(newColumns);
    }
  };

  const handleMouseUp = () => {
    resizingColumnRef.current = null;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleRowContextMenu = (
    record: CaseDetail,
    event: React.MouseEvent
  ) => {
    event.preventDefault();
    setSelectedRow(record);
    setMenuPosition({ x: event.pageX, y: event.pageY });
    setMenuVisible(true);
  };

  useEffect(() => {
    const initialFilters = getInitialFiltersFromURL(location.search);
    setFormValues(initialFilters);
    handleFetchCases(initialFilters);
  }, [location.search]);

  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const adminFeatures = me?.adminFeatures?.map(
      (adminFeature) => adminFeature.feature
    );

    setColumns([
      {
        title: "Tags",
        dataIndex: "id",
        key: "id",
        width: 75,
        ellipsis: true,
        fixed: screenWidth > 900 ? "left" : undefined,
        render: (_: number, caseDetail: CaseDetail) => (
          <div className="scan-center-cases__tags">
            {caseDetail?.tags?.map((tag) => (
              <Tooltip title={tag.formattedName} key={tag.id}>
                <span
                  className="scan-center-cases__tag-item"
                  style={{ backgroundColor: tag.color }}
                ></span>
              </Tooltip>
            ))}
          </div>
        ),
      },
      {
        title: "Patient ID",
        dataIndex: "patientId",
        key: "patientId",
        width: 100,
        fixed: screenWidth > 900 ? "left" : undefined,
        onCell: getCustomCellStyles(100),
      },
      {
        title: "Patient name",
        dataIndex: "patientName",
        key: "patientName",
        width: 150,
        ellipsis: true,
        fixed: screenWidth > 900 ? "left" : undefined,
        onCell: getCustomCellStyles(150),
      },
      {
        title: "Study",
        dataIndex: "scanTypeName",
        key: "scanTypeName",
        width: 120,
        ellipsis: true,
        onCell: getCustomCellStyles(120),
        render: (_: string, record: CaseDetail) => (
          <span className="admin-cases__study">{record?.getStudyName()}</span>
        ),
      },
      ...(me?.adminType === AdminTypeEnum.SCAN_CENTER_ADMIN ||
      me?.adminType === AdminTypeEnum.DIGITAL_SHARING_ADMIN
        ? [
            {
              title: "Referring Doctor",
              dataIndex: "referringDoctor",
              key: "referringDoctor",
              width: 100,
              ellipsis: true,
              onCell: getCustomCellStyles(100),
            },
          ]
        : []),
      ...(adminFeatures &&
      adminFeatures?.includes(AdminFeatureEnum.CUSTOMISED_COLUMNS)
        ? [
            {
              title: "Patient History",
              dataIndex: "parsedPatientHistory",
              key: "parsedPatientHistory",
              width: 150,
              ellipsis: true,
              onCell: getCustomCellStyles(150),
            },
          ]
        : []),
      {
        title: "Date Uploaded",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 175,
        onCell: getCustomCellStyles(175),
        ellipsis: true,
        render: (date: string) => (
          <span>
            {date ? moment(date).format("DD-MMM-YYYY hh:mm A") : "NA"}
          </span>
        ),
      },
      {
        title: "Case Status",
        dataIndex: "caseStatus",
        key: "caseStatus",
        width: 130,
        onCell: getCustomCellStyles(130),
        ellipsis: true,
        render: (status: string) => {
          const caseStatus =
            status === CaseStatusEnum.ASSIGNED ||
            status === CaseStatusEnum.TO_BE_APPROVED
              ? CaseStatusEnum.REPORT_PENDING
              : status;
          return (
            <span className={`scan-center-cases__status ${caseStatus}`}>
              {formatStatus(caseStatus)}
            </span>
          );
        },
      },
      {
        title: "Upload Status",
        dataIndex: "uploadStatus",
        key: "uploadStatus",
        width: 120,
        onCell: getCustomCellStyles(120),
        ellipsis: true,
        render: (status: string, caseDetail: CaseDetail) =>
          caseDetail?.id && (
            <span className="text-capitalize scan-center-cases__status-loader">
              {completedCaseIds?.includes(caseDetail?.id)
                ? "Complete"
                : uploadStartedCaseIds?.includes(caseDetail?.id)
                ? "Upload Started"
                : pendingCaseIds?.includes(caseDetail?.id)
                ? "Pending"
                : status.split("_").join(" ")}
              {caseDetail?.id &&
                (pendingCaseIds?.includes(caseDetail?.id) ||
                  uploadStartedCaseIds?.includes(caseDetail?.id)) && (
                  <AppLoader loading />
                )}
            </span>
          ),
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "id",
        render: (id: number, caseDetail: CaseDetail) => (
          <div onClick={(e) => e.stopPropagation()}>
            {/*{caseDetail?.caseStatus !== CaseStatusEnum.REPORTED && (*/}
            {/*    <Tooltip title="Urgent Tag">*/}
            {/*      <Switch*/}
            {/*          checkedChildren="U"*/}
            {/*          unCheckedChildren="N"*/}
            {/*          style={{ backgroundColor: caseDetail.isUrgent? "red" : "gray" }} // ✅ Tie to caseDetail*/}
            {/*          checked={caseDetail.isUrgent} // ✅ Ensures correct state*/}
            {/*          onChange={(checked, event) => {*/}
            {/*            handleToggleUrgency(checked, caseDetail, event.target); // ✅ Pass event target*/}
            {/*          }}*/}
            {/*      />*/}
            {/*    </Tooltip>*/}
            {/*)}*/}
            {caseDetail?.caseStatus !== CaseStatusEnum.REPORTED && (
              <Tooltip title="Edit Case">
                <Button
                  className="ml-2"
                  icon={<EditOutlined />}
                  onClick={handleEditCase(caseDetail)}
                />
              </Tooltip>
            )}

            {adminFeatures?.includes(AdminFeatureEnum.DIGITAL_SHARING) && (
              <Tooltip title="Copy Share Link">
                <Button
                  icon={<CopyOutlined />}
                  className="ml-2 clickable-icon"
                  onClick={() => {
                    Notification({
                      message: "Copied",
                      type: NotificationTypes.SUCCESS,
                    });
                    handleCaseShareLink(caseDetail);
                  }}
                />
              </Tooltip>
            )}

            {caseDetail?.caseStatus === CaseStatusEnum.REPORTED && (
              <Tooltip title="Download Report">
                <Popover
                  destroyTooltipOnHide
                  overlayClassName="admin-cases__assignment-popover"
                  placement="bottomLeft"
                  content={
                    <div>
                      <Menu mode="vertical" className="border-none">
                        <Menu.Item
                          className="navbar-item m-0"
                          key="with_letterhead"
                          onClick={() => {
                            handleDownloadCase(
                              caseDetail,
                              DownloadFileTypeEnum.PDF,
                              true
                            );
                          }}
                        >
                          Download with Letterhead
                        </Menu.Item>
                        <Menu.Item
                          className="navbar-item m-0"
                          key="without_letterhead"
                          onClick={() => {
                            handleDownloadCase(
                              caseDetail,
                              DownloadFileTypeEnum.PDF,
                              false
                            );
                          }}
                        >
                          Download without Letterhead
                        </Menu.Item>
                        <Menu.Item
                          className="navbar-item m-0"
                          key="as_word"
                          onClick={() => {
                            handleDownloadCase(
                              caseDetail,
                              DownloadFileTypeEnum.WORD,
                              false
                            );
                          }}
                        >
                          Download as Word
                        </Menu.Item>
                      </Menu>
                    </div>
                  }
                  title="Download Report"
                  trigger="click"
                >
                  <Button
                    loading={
                      activeCase?.id === caseDetail?.id && downloadLoading
                    }
                    icon={<DownloadOutlined />}
                    className="ml-2"
                  />
                </Popover>
              </Tooltip>
            )}
          </div>
        ),
      },
    ]);
  }, [me, me?.adminType, caseDetails]);

  const updatedColumns = columns.map((col: any, index: any) => ({
    ...col,
    title: (
      <div className="resizable-header">
        {col.title}
        <div
          className="resizer"
          onMouseDown={(e) => handleMouseDown(e, index)}
        />
      </div>
    ),
  }));

  return (
    <div className="scan-center-cases">
      <AppCard>
        <Row>
          <Col span={12}>
            <h2 className="mt-2">Cases</h2>
          </Col>
          <Col span={12} className="text-right">
            <Button type="primary" onClick={handleAddCase}>
              <PlusOutlined /> Add Case
            </Button>
          </Col>
        </Row>
        <CaseFilterForm
          isScanCenter
          formValues={formValues}
          // onSubmit={handleSubmit}
          // onReset={handleReset}
          // onChange={handleOnChange}
        />
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            dataSource={caseDetails}
            rowClassName="cursor-pointer"
            onRow={handleRowChange}
            scroll={{ x: "max-content", y: `calc(100vh - 410px)` }}
            columns={updatedColumns}
            pagination={{
              current: paginationMeta?.currentPage,
              pageSize: paginationMeta?.perPage,
              total: paginationMeta?.totalCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "15", "20", "50"],
            }}
            onChange={handlePageChange}
          />
        )}
        {caseDetails.length > 0 && <TagLegend />}
        <Modal
          destroyOnClose
          visible={showCaseOverview}
          className="primary-modal"
          onCancel={handleCloseCaseOverview}
          footer={null}
          maskClosable={false}
        >
          {activeCase && (
            <CaseOverview
              activeCaseId={activeCase?.id}
              onCancel={handleCloseCaseOverview}
            />
          )}
        </Modal>

        <Modal
          destroyOnClose
          visible={showCaseForm}
          className="primary-modal"
          onCancel={handleToggleCaseForm}
          footer={null}
          maskClosable={false}
        >
          {activeCase && (
            <CaseForm
              caseDetailId={activeCase?.id}
              onSuccess={handleUploadCaseSuccess}
              onClose={() => setShowCaseForm(false)}
            />
          )}
        </Modal>
      </AppCard>
      <ContextMenu
        visible={menuVisible}
        position={menuPosition}
        selectedRow={selectedRow}
        onClose={() => setMenuVisible(false)}
        onUpdate={handleUpdateTags}
      />
    </div>
  );
}

export default CaseContainer(ScanCenterCases);
