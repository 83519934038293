import { deserialize, serialize } from "serializr";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import axiosInstance from "../../../interceptor/axiosInstance";
import ApiRoutes from "../../../routes/routeConstants/apiRoutes";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import { DicomData } from "../../../models/DicomData/dicomData.model";
import { DicomDataThumbnail } from "../../../models/DicomDataThumbnail/dicomDataThumbnail.model";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { PaginationMeta } from "../../../models/PaginationMeta/paginationMeta.model";

export default class DoctorCaseService {
  static fetchDoctorCases(
    filterParams: CaseFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(filterParams);
    axiosInstance
      .get(ApiRoutes.DOCTOR_CASES, {
        params,
      })
      .then((response) => {
        const doctorCases = deserialize(
          CaseDetail,
          response.data["case_details"]
        );
        const paginationMeta = deserialize(
          PaginationMeta,
          response.data["meta"]
        );
        onSuccess(doctorCases, paginationMeta);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static selfAssignCase(
    caseDetail: CaseDetail,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const caseDetailJSON = { case_detail: serialize(caseDetail) };
    const API_URL = ApiRoutes.DOCTOR_SELF_ASSIGN.replace(
      ":caseDetailId",
      caseDetail?.id?.toString() || ""
    );
    axiosInstance
      .post(API_URL, caseDetailJSON)
      .then((response) => {
        Notification({
          message: "Case has been assigned to you!",
          type: NotificationTypes.SUCCESS,
        });
        const doctorCase = deserialize(
          CaseDetail,
          response.data["case_detail"]
        );
        onSuccess(doctorCase);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static RemoveCaseAssignment(
    caseDetail: CaseDetail,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const caseDetailJSON = { case_detail: serialize(caseDetail) };
    const API_URL = ApiRoutes.DOCTOR_REMOVE_ASSIGNMENT.replace(
      ":caseDetailId",
      caseDetail?.id?.toString() || ""
    );
    axiosInstance
      .post(API_URL, caseDetailJSON)
      .then((response) => {
        Notification({
          message: "Assignment removed. Case unlocked!",
          type: NotificationTypes.SUCCESS,
        });
        const doctorCase = deserialize(
          CaseDetail,
          response.data["case_detail"]
        );
        onSuccess(doctorCase);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showDoctorCase(
    doctorCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DOCTOR_CASES + "/" + doctorCaseId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const doctorCase = deserialize(
          CaseDetail,
          response.data["case_detail"]
        );
        onSuccess(doctorCase);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadDoctorCase(
    doctorCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DOCTOR_DICOM_DOWNLOAD.replace(
      ":caseDetailId",
      doctorCaseId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        onSuccess(response.data["url"]);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showDoctorCaseDirectories(
    doctorCaseId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DOCTOR_CASE_THUMBNAILS.replace(
      ":caseDetailId",
      doctorCaseId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((res) => {
        const dicomDataThumbnails = deserialize(
          DicomDataThumbnail,
          res.data as any[]
        );
        onSuccess(dicomDataThumbnails);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showDoctorCaseDirectoryFiles(doctorCaseId: number, folder: string) {
    const API_URL = ApiRoutes.DOCTOR_CASE_DIRECTORY_FILES.replace(
      ":caseDetailId",
      doctorCaseId.toString()
    );
    return axiosInstance.get(API_URL, {
      params: {
        folder,
      },
    });
  }
}
