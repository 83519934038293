import {
  AUTHENTICATED,
  SET_USER,
  SET_USER_ROLE,
  UNAUTHENTICATED,
} from "../definitions/authConstants";
import { UserRoleEnum } from "../../enums/userRole.enum";
import { ScanCenter } from "../../models/ScanCenter/scanCenter.model";
import { Doctor } from "../../models/Doctor/doctor.model";
import { Admin } from "../../models/Admin/admin.model";
import { AdminTypeEnum } from "../../enums/AdminType.enum";

export interface SetAuthenticatedAction {
  type: typeof AUTHENTICATED;
  payload: { authenticated: boolean; user?: ScanCenter | Doctor | Admin };
}

export interface SetUnauthenticatedAction {
  type: typeof UNAUTHENTICATED;
  payload: { authenticated: boolean };
}

export interface SetUserRoleAction {
  type: typeof SET_USER_ROLE;
  payload: { userRole: UserRoleEnum; adminType?: AdminTypeEnum };
}

export interface SetUserAction {
  type: typeof SET_USER;
  payload: { user: ScanCenter | Doctor | Admin };
}

export const setAuthenticated = (
  user: ScanCenter | Doctor | Admin
): SetAuthenticatedAction => ({
  type: AUTHENTICATED,
  payload: {
    authenticated: true,
    user,
  },
});

export const setUnAuthenticated = (): SetUnauthenticatedAction => {
  return {
    type: UNAUTHENTICATED,
    payload: {
      authenticated: false,
    },
  };
};

export const setUser = (user: Doctor | Admin | ScanCenter): SetUserAction => {
  return {
    type: SET_USER_ROLE,
    payload: {
      user,
    },
  };
};

export const setUserRole = (
  userRole: UserRoleEnum,
  adminType?: AdminTypeEnum
): SetUserRoleAction => {
  return {
    type: SET_USER_ROLE,
    payload: {
      userRole,
      adminType,
    },
  };
};
