import React, { useRef, useEffect, useState } from "react";
import "./contextMenu.scss";
import TagService from "../../../services/TagService/tag.service";
import { Tag } from "../../../models/Tag/tag.model";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { UserRoleEnum } from "../../../enums/userRole.enum";

interface ContextMenuProps extends AuthReducerProps {
  visible: boolean;
  position: { x: number; y: number };
  selectedRow: CaseDetail;
  onClose: () => void;
  onUpdate: (caseId: number, tags: Tag[]) => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  visible,
  position,
  selectedRow,
  onClose,
  onUpdate,
  userRole,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const [tags, setTags] = useState<Tag[]>([]);

  const handleUpdateTags = (tagId: number) => {
    if (selectedRow.tags && selectedRow.id) {
      let tagIds = selectedRow?.tags.map((tag: Tag) => tag?.id);
      if (tagIds.includes(tagId)) {
        tagIds = tagIds.filter((prevTagId) => prevTagId !== tagId);
      } else {
        tagIds.push(tagId);
      }
      TagService.updateAdminTags(
        selectedRow.id,
        userRole as UserRoleEnum,
        tagIds,
        (tags: Tag[]) => {
          selectedRow.id && onUpdate(selectedRow.id, tags);
        },
        () => {},
        () => {}
      );
    }
  };

  useEffect(() => {
    TagService.fetchAdminTags(
      userRole as UserRoleEnum,
      (tags: Tag[]) => {
        setTags(tags);
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClose]);

  if (!visible) return null;

  return (
    <div
      ref={menuRef}
      className="context-menu"
      style={{ top: position.y, left: position.x }}
    >
      {tags.map((tag) => (
        <button
          key={tag?.id}
          className="menu-item"
          onClick={() => {
            if (tag?.id) handleUpdateTags(tag?.id);
            onClose();
          }}
        >
          <span
            className="menu-item__legend"
            style={{ backgroundColor: tag?.color }}
          ></span>
          <span className="menu-item__label">
            {tag?.name?.split("_").join(" ")}
          </span>
          {selectedRow?.parsedTagIds.includes(tag?.id) && (
            <span className="menu-item__checked">✓</span>
          )}
        </button>
      ))}
    </div>
  );
};

export default AuthContainer(ContextMenu);
