import React, { useEffect, useState } from "react";
import "./tagLegend.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { AdminFeatureEnum } from "../../../enums/AdminFeature.enum";
import { Tag } from "../../../models/Tag/tag.model";
import TagService from "../../../services/TagService/tag.service";
import { UserRoleEnum } from "../../../enums/userRole.enum";

interface TagLegendProps extends AuthReducerProps {}

const TagLegend = ({ userRole }: TagLegendProps) => {
  const [tagOptions, setTagOptions] = useState<Tag[]>([]);

  useEffect(() => {
    TagService.fetchAdminTags(
      userRole as UserRoleEnum,
      (tags: Tag[]) => {
        setTagOptions(tags);
      },
      () => {},
      () => {}
    );
  }, [userRole]);

  return (
    <div className="tag-legend">
      {tagOptions.map((option) => (
        <span className="tag-legend__legend-item" key={option.color}>
          <span
            className="tag-legend__color"
            style={{ backgroundColor: option.color }}
          ></span>
          <span className="tag-legend__name">{option?.formattedName}</span>
        </span>
      ))}
    </div>
  );
};

export default AuthContainer(TagLegend);
