import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Report } from "../../models/Report/report.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { DownloadFileTypeEnum } from "../../enums/downloadFileType.enum";
import { CaseDetail } from "../../models/CaseDetail/caseDetail.model";
import { formatFileNameFromCaseDetail } from "../../shared/utils/dataFormatConverter";

export default class ReportService {
  static fetchReports(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.REPORTS)
      .then((response) => {
        const reports = deserialize(Report, response.data["reports"]);
        onSuccess(reports);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showReport(
    reportId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.REPORTS + "/" + reportId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        if (response.data.report) {
          const report = deserialize(Report, response.data["report"]);
          onSuccess(report);
        } else {
          onError();
        }
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showAdminReport(
    caseDetailId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ADMIN_REPORTS.replace(
      ":caseDetailId",
      caseDetailId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        if (response.data.report) {
          const report = deserialize(Report, response.data["report"]);
          onSuccess(report);
        } else {
          onError();
        }
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createReport(
    report: Report,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const reportJSON = { report: serialize(report) };
    axiosInstance
      .post(ApiRoutes.REPORTS, reportJSON)
      .then((response) => {
        Notification({
          message: "Report created",
          type: NotificationTypes.SUCCESS,
        });
        const report = deserialize(Report, response.data["report"]);
        onSuccess(report);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateReport(
    report: Report,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.REPORTS + "/" + report.id;
    const reportJSON = { report: serialize(report) };
    axiosInstance
      .put(API_URL, reportJSON)
      .then((response) => {
        Notification({
          message: "Report updated",
          type: NotificationTypes.SUCCESS,
        });
        const report = deserialize(Report, response.data["report"]);
        onSuccess(report);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static publishReport(
    report: Report,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const reportJSON = { report: serialize(report) };
    let API_URL = ApiRoutes.PUBLISH_REPORT;
    if (report?.id) {
      API_URL = API_URL.replace(":reportId", report.id.toString());
    }
    axiosInstance
      .put(API_URL, reportJSON)
      .then((response) => {
        Notification({
          message: "Report published",
          type: NotificationTypes.SUCCESS,
        });
        const report = deserialize(Report, response.data["report"]);
        onSuccess(report);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static approveAndPublishReport(
    report: Report,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const reportJSON = { report: serialize(report) };
    let API_URL = ApiRoutes.APPROVE_AND_PUBLISH_REPORT;
    if (report?.id) {
      API_URL = API_URL.replace(":reportId", report.id.toString());
    }
    axiosInstance
      .put(API_URL, reportJSON)
      .then((response) => {
        Notification({
          message: "Report approved and published",
          type: NotificationTypes.SUCCESS,
        });
        const report = deserialize(Report, response.data["report"]);
        onSuccess(report);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static sendForApproval(
    report: Report,
    caseDetailId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const reportJSON = { report: serialize(report) };
    const API_URL = ApiRoutes.SEND_FOR_APPROVAL.replace(
      ":caseDetailId",
      caseDetailId.toString()
    );
    axiosInstance
      .post(API_URL, reportJSON)
      .then((response) => {
        Notification({
          message: "Report sent for approval",
          type: NotificationTypes.SUCCESS,
        });
        const report = deserialize(Report, response.data["report"]);
        onSuccess(report);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadReport(
    fileType: DownloadFileTypeEnum,
    caseDetail: CaseDetail,
    withLetterHead: boolean,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    if (caseDetail?.id) {
      let API_URL = ApiRoutes.DOWNLOAD_PDF.replace(
        ":caseDetailId",
        caseDetail?.id.toString()
      );
      let api = axiosInstance.get(API_URL);
      if (fileType === DownloadFileTypeEnum.WORD) {
        API_URL = ApiRoutes.DOWNLOAD_WORD.replace(
          ":caseDetailId",
          caseDetail?.id.toString()
        );
        api = axiosInstance.get(API_URL, { responseType: "blob" });
      }
      api
        .then((response) => {
          if (fileType === DownloadFileTypeEnum.WORD) {
            const blob = new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadURL;
            link.download = formatFileNameFromCaseDetail(caseDetail) + ".docx";
            link.click();
          } else {
            onSuccess(
              withLetterHead
                ? response.data.letter_head_report
                : response.data.report
            );
          }
        })
        .catch((error) => {
          onError();
        })
        .finally(() => {
          onFinal();
        });
    }
  }

  static downloadApprovalReport(
    fileType: DownloadFileTypeEnum,
    caseDetail: CaseDetail,
    withLetterHead: boolean,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    if (caseDetail?.id) {
      let API_URL = ApiRoutes.APPROVAL_DOWNLOAD_PDF.replace(
        ":caseDetailId",
        caseDetail?.id.toString()
      );
      let api = axiosInstance.get(API_URL);
      if (fileType === DownloadFileTypeEnum.WORD) {
        API_URL = ApiRoutes.APPROVAL_DOWNLOAD_WORD.replace(
          ":caseDetailId",
          caseDetail?.id.toString()
        );
        api = axiosInstance.get(API_URL, { responseType: "blob" });
      }
      api
        .then((response) => {
          if (fileType === DownloadFileTypeEnum.WORD) {
            const blob = new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadURL;
            link.download = formatFileNameFromCaseDetail(caseDetail) + ".docx";
            link.click();
          } else {
            onSuccess(
              withLetterHead
                ? response.data.letter_head_report
                : response.data.report
            );
          }
        })
        .catch((error) => {
          onError();
        })
        .finally(() => {
          onFinal();
        });
    }
  }

  static downloadScanCenterReport(
    fileType: DownloadFileTypeEnum,
    caseDetail: CaseDetail,
    withLetterHead: boolean,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const caseDetailId = caseDetail?.id ?? 0;
    let API_URL = ApiRoutes.SCAN_CENTER_DOWNLOAD_PDF.replace(
      ":caseDetailId",
      caseDetailId.toString()
    );
    let api = axiosInstance.get(API_URL);
    if (fileType === DownloadFileTypeEnum.WORD) {
      API_URL = ApiRoutes.SCAN_CENTER_DOWNLOAD_WORD.replace(
        ":caseDetailId",
        caseDetailId.toString()
      );
      api = axiosInstance.get(API_URL, { responseType: "blob" });
    }
    api
      .then((response) => {
        if (fileType === DownloadFileTypeEnum.WORD) {
          const blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadURL;
          link.download = formatFileNameFromCaseDetail(caseDetail) + ".docx";
          link.click();
        } else {
          onSuccess(
            withLetterHead
              ? response.data.letter_head_report
              : response.data.report
          );
        }
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static previewReport(
    caseDetailId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    let API_URL = ApiRoutes.PREVIEW_REPORT.replace(
      ":caseDetailId",
      caseDetailId.toString()
    );
    axiosInstance
      .get(API_URL, {
        responseType: "blob",
      })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static previewApprovalReport(
    caseDetailId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    let API_URL = ApiRoutes.APPROVAL_PREVIEW_REPORT.replace(
      ":caseDetailId",
      caseDetailId.toString()
    );
    axiosInstance
      .get(API_URL, {
        responseType: "blob",
      })
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteReport(
    reportId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.REPORTS + "/" + reportId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
