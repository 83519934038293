import React, { useEffect, useState, useRef } from "react";
import "./doctorCases.scss";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import {
  Button,
  Drawer,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Table,
  TablePaginationConfig,
  Tooltip,
  Input,
  Row,
  Col,
} from "antd";
import moment from "moment";
import DoctorCaseService from "../../../services/Case/DoctorCase/doctorCase.service";
import CaseOverview from "../../../shared/components/CaseOverview";
import {
  copyCaseDetailsToClipboard,
  stopPropogateEvent,
} from "../../../shared/utils/eventUtils";
import { CaseBodyPart } from "../../../models/CaseBodyPart/caseBodyPart.model";
import {
  DownloadOutlined,
  CloudDownloadOutlined,
  FormOutlined,
  EllipsisOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import ReportForm from "../../../shared/components/ReportForm";
import ReportService from "../../../services/Report/report.service";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import { hasDoctorAccessToCase } from "../../../shared/utils/accessUtils";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import CaseFilterForm from "../../../shared/components/CaseFilterForm";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import {
  getCustomCellStyles,
  formatStatus,
  getInitialFiltersFromURL,
} from "../../../shared/utils/dataFormatConverter";
import { generatePath, useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import CaseContainer from "../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../store/reducers/caseReducer";
import { Switch } from "antd";
import ProfileService from "../../../services/Profile/profile.service";
import { Doctor } from "../../../models/Doctor/doctor.model";
import { useLocation } from "react-router-dom";
import SharedCaseService from "../../../services/Shared/shared.service";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { PaginationMeta } from "../../../models/PaginationMeta/paginationMeta.model";
import { AdminFeatureEnum } from "../../../enums/AdminFeature.enum";
import { AdminTypeEnum } from "../../../enums/AdminType.enum";
import { ColumnsType } from "antd/lib/table";
import ContextMenu from "../../../shared/components/ContextMenu";
import { Tag } from "../../../models/Tag/tag.model";
import TagLegend from "../../../shared/components/TagLegend";

interface DoctorCasesProps extends CaseReducerProps {}

function DoctorCases({ newCaseDetail, updatedCaseDetail }: DoctorCasesProps) {
  const location = useLocation();

  const [me, setMe] = useState<Doctor>();

  const [caseDetails, setCaseDetails] = useState<CaseDetail[]>([]);

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [downloadZipLoading, setDownloadZipLoading] = useState(false);

  const [activeCase, setActiveCase] = useState<CaseDetail>();

  const [showCaseOverview, setShowCaseOverview] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [activePopoverId, setActivePopoverId] = useState<number | null>(null);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [formValues, setFormValues] = useState<CaseFilterParams>(
    Object.assign(new CaseFilterParams(), {
      ...new CaseFilterParams(),
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    })
  );

  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>(
    Object.assign(new PaginationMeta(), {
      currentPage: 1,
      totalCount: 0,
      totalPages: 0,
    })
  );

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const resizingColumnRef = useRef(null);

  const startXRef = useRef(0);

  const startWidthRef = useRef(0);

  const [menuVisible, setMenuVisible] = useState(false);

  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const [selectedRow, setSelectedRow] = useState<any>(null);

  const menuRef = useRef<HTMLDivElement>(null);

  // const adminFeatures = me?.adminFeatures?.map(
  //   (adminFeature) => adminFeature.feature
  // );

  const handleSubmit = (values: CaseFilterParams) => {
    const formValues = Object.assign(new CaseFilterParams(), values);
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleOnChange = (updatedValues: CaseFilterParams) => {
    // Update the form values with new inputs
    const newFormValues = Object.assign(new CaseFilterParams(), updatedValues);
    setFormValues(newFormValues);

    // Clear any existing debounce timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Set a new debounce timer
    debounceTimerRef.current = setTimeout(() => {
      // handleFetchCases(newFormValues); // Call the fetch function with the updated form values
    }, 500); // 500ms debounce delay
  };

  // Cleanup on component unmount to clear the debounce timer
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handleReset = () => {
    const formValues = new CaseFilterParams();
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleToggleReportForm = () => setShowReportForm(!showReportForm);

  const handleCloseCaseOverview = () => {
    setActiveCase(undefined);
    setShowCaseOverview(false);
  };

  const handleRowContextMenu = (
    record: CaseDetail,
    event: React.MouseEvent
  ) => {
    event.preventDefault();
    setSelectedRow(record);
    setMenuPosition({ x: event.pageX, y: event.pageY });
    setMenuVisible(true);
  };

  const history = useHistory();

  const handleRowChange = (caseDetail: CaseDetail, _: number | undefined) => {
    return {
      onClick: (event: any) => {
        event.preventDefault(); // Prevent default behavior if needed
        if (caseDetail?.id) {
          window.open(
            generatePath(AppRoutes.CASE_DETAIL, {
              caseId: caseDetail.id,
            }),
            "_blank" // This opens the URL in a new tab
          );
        }
      },
      onContextMenu: (event: React.MouseEvent) =>
        handleRowContextMenu(caseDetail, event),
    };
  };

  const handleUpdateTags = (caseId: number, tags: Tag[]) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseId
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex].tags = tags;
    }
    setCaseDetails([...caseDetails]);
  };

  const handlePageChange = (pagination: TablePaginationConfig) => {
    const values = Object.assign(new CaseFilterParams(), {
      ...formValues,
      page: pagination.current,
      perPage: pagination.pageSize,
    });
    updateUrlParams(values);
  };

  // Initial data fetch with URL parameters
  useEffect(() => {
    const initialFilters = getInitialFiltersFromURL(location.search);
    setFormValues(initialFilters);
    handleFetchCases(initialFilters);
  }, [location.search]); // Re-fetch when URL changes

  useEffect(() => {
    ProfileService.getProfile(
      UserRoleEnum.DOCTOR,
      (doctor: Doctor) => {
        setMe(doctor);
      },
      () => {},
      () => {}
    );
  }, []);

  // Update URL with current filter values
  const updateUrlParams = (values: CaseFilterParams) => {
    const searchParams = new URLSearchParams();

    // Add non-empty filter values to URL
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        searchParams.set(key, String(value));
      }
    });

    // Update URL without page reload
    history.replace(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleFetchCases = (formValues: CaseFilterParams) => {
    setLoading(true);
    DoctorCaseService.fetchDoctorCases(
      formValues,
      (caseDetails: CaseDetail[], paginationMeta: PaginationMeta) => {
        setCaseDetails(caseDetails);
        setPaginationMeta(paginationMeta);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleDownloadDetails = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      setDownloadZipLoading(true);
      DoctorCaseService.downloadDoctorCase(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          window.open(targetUrl, "_blank");
        },
        () => {},
        () => {
          setDownloadZipLoading(false);
        }
      );
    }
  };

  const handleSelfAssignCase = (caseDetail: CaseDetail) => {
    DoctorCaseService.selfAssignCase(
      caseDetail,
      (caseDetail: CaseDetail) => {
        const caseIndex = caseDetails.findIndex(
          (caseItem) => caseItem.id === caseDetail.id
        );
        if (caseIndex >= 0) {
          caseDetails[caseIndex] = caseDetail;
        }
        setCaseDetails([...caseDetails]);
      },
      () => {},
      () => {}
    );
  };

  const handleCaseAssignment = (checked: boolean, caseDetail: any) => {
    // If you need to pass the specific case detail, you'll need to adjust how you're handling this
    if (checked) {
      DoctorCaseService.RemoveCaseAssignment(
        caseDetail, // Assuming caseDetail is in scope or passed differently
        (caseDetail: CaseDetail) => {
          const caseIndex = caseDetails.findIndex(
            (caseItem) => caseItem.id === caseDetail.id
          );
          if (caseIndex >= 0) {
            const newCaseDetails = [...caseDetails];
            newCaseDetails[caseIndex] = caseDetail;
            setCaseDetails(newCaseDetails);
          }
        },
        () => {},
        () => {}
      );
    } else {
      DoctorCaseService.selfAssignCase(
        caseDetail,
        (caseDetail: CaseDetail) => {
          const caseIndex = caseDetails.findIndex(
            (caseItem) => caseItem.id === caseDetail.id
          );
          if (caseIndex >= 0) {
            caseDetails[caseIndex] = caseDetail;
          }
          setCaseDetails([...caseDetails]);
        },
        () => {},
        () => {}
      );
    }
  };

  const handleDownloadReport = (
    caseDetail: CaseDetail,
    caseType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => {
    setActiveCase(caseDetail);
    setDownloadLoading(true);
    if (caseDetail?.id) {
      ReportService.downloadReport(
        caseType,
        caseDetail,
        withLetterHead,
        (reportUrl: string) => {
          window.open(reportUrl, "_blank");
        },
        () => {},
        () => {
          setActiveCase(undefined);
          setDownloadLoading(false);
        }
      );
    }
  };

  const handleCaseShareLink = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      SharedCaseService.getCaseShareLink(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          copyCaseDetailsToClipboard(caseDetail, targetUrl);
        },
        () => {},
        () => {}
      );
    }
  };

  useEffect(() => {
    if (newCaseDetail) {
      handleFetchCases(formValues);
      // caseDetails.unshift(newCaseDetail);
      // setCaseDetails([...caseDetails]);
    }
  }, [newCaseDetail]);

  useEffect(() => {
    if (updatedCaseDetail) {
      const caseIndex = caseDetails.findIndex(
        (caseDetail) => caseDetail.id === updatedCaseDetail.id
      );
      if (caseIndex >= 0) {
        caseDetails[caseIndex] = updatedCaseDetail;
        setCaseDetails([...caseDetails]);
      }
      // Commented for future use
      // handleFetchCases(formValues);
    }
  }, [updatedCaseDetail]);

  const handleMouseDown = (e: any, index: any) => {
    resizingColumnRef.current = index;
    startXRef.current = e.clientX;
    startWidthRef.current = columns[index].width;

    const screenWidth = window.innerWidth;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: any) => {
    if (resizingColumnRef.current !== null) {
      const newWidth = startWidthRef.current + (e.clientX - startXRef.current);
      const newColumns = [...columns];
      newColumns[resizingColumnRef.current].width = Math.max(newWidth, 100);
      setColumns(newColumns);
    }
  };

  const handleMouseUp = () => {
    resizingColumnRef.current = null;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const adminFeatures = me?.adminFeatures?.map(
      (adminFeature) => adminFeature.feature
    );

    setColumns([
      {
        title: "Tags",
        dataIndex: "id",
        key: "id",
        width: 75,
        ellipsis: true,
        fixed: screenWidth > 900 ? "left" : undefined,
        render: (_: number, caseDetail: CaseDetail) => (
          <div className="doctor-cases__tags">
            {caseDetail?.tags?.map((tag) => (
              <Tooltip title={tag.formattedName} key={tag.id}>
                <span
                  className="doctor-cases__tag-item"
                  style={{ backgroundColor: tag.color }}
                ></span>
              </Tooltip>
            ))}
          </div>
        ),
      },
      {
        title: "Patient ID",
        dataIndex: "patientId",
        key: "patientId",
        width: 100,
        fixed: screenWidth > 900 ? "left" : undefined,
        ellipsis: true,
        render: (patientId: string, caseDetail: CaseDetail) => (
          <div
            className="admin-cases__patient-id"
            style={{ display: "flex", alignItems: "center" }}
          >
            {patientId}
            {/* {caseDetail.isUrgent && (
                      <span
                        style={{
                          width: 9,
                          height: 9,
                          backgroundColor: "red",
                          borderRadius: "50%",
                          marginLeft: 6,
                          animation: "blink 2s infinite",
                        }}
                      />
                    )} */}
          </div>
        ),
        onCell: getCustomCellStyles(100),
      },
      {
        title: "Patient Name",
        dataIndex: "patientName",
        key: "patientName",
        width: 150,
        fixed: screenWidth > 900 ? "left" : undefined,
        ellipsis: true,
        onCell: getCustomCellStyles(150),
      },
      {
        title: "Study",
        dataIndex: "scanTypeName",
        key: "scanTypeName",
        width: 100,
        ellipsis: true,
        onCell: getCustomCellStyles(100),
        render: (_: string, record: CaseDetail) => (
          <span className="admin-cases__study">{record?.getStudyName()}</span>
        ),
      },
      ...(adminFeatures &&
      adminFeatures?.includes(AdminFeatureEnum.CUSTOMISED_COLUMNS)
        ? [
            {
              title: "Patient History",
              dataIndex: "parsedPatientHistory",
              key: "parsedPatientHistory",
              width: 150,
              ellipsis: true,
              onCell: getCustomCellStyles(150),
            },
          ]
        : []),
      {
        title: "Scan Center",
        dataIndex: "scanCenterName",
        key: "scanCenterName",
        width: 100,
        ellipsis: true,
        onCell: getCustomCellStyles(100),
      },
      ...(me?.adminType === AdminTypeEnum.SCAN_CENTER_ADMIN ||
      me?.adminType === AdminTypeEnum.DIGITAL_SHARING_ADMIN
        ? [
            {
              title: "Referring Doctor",
              dataIndex: "referringDoctor",
              key: "referringDoctor",
              width: 150,
              ellipsis: true,
              onCell: getCustomCellStyles(150),
            },
          ]
        : []),
      {
        title: "Date Uploaded",
        dataIndex: "createdAt",
        key: "createdAt",
        ellipsis: true,
        width: 150,
        onCell: getCustomCellStyles(150),
        sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
        render: (date: string) => (
          <span>
            {date ? (
              <span>
                {moment(date).format("DD-MMM-YY")}{" "}
                {moment(date).format("hh:mm A")}
              </span>
            ) : (
              "NA"
            )}
          </span>
        ),
      },
      {
        title: "Due Time",
        dataIndex: "dueDate",
        key: "dueDate",
        width: 100,
        onCell: getCustomCellStyles(100),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 100,
        onCell: getCustomCellStyles(100),
        sorter: (a: any, b: any) => a.status.localeCompare(b.status),
        render: (status: string, caseDetail: CaseDetail) => {
          return (
            <div
              className="doctor-cases__status-wrapper"
              onClick={stopPropogateEvent}
            >
              <span className={`doctor-cases__status ${status}`}>
                {formatStatus(status)}
              </span>
            </div>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "id",
        render: (status: string, caseDetail: CaseDetail) => {
          return (
            <div onClick={stopPropogateEvent}>
              {(status === CaseStatusEnum.UNASSIGNED ||
                status === CaseStatusEnum.ASSIGNED) && (
                <Tooltip
                  title={
                    status === CaseStatusEnum.ASSIGNED
                      ? "Remove Assignment"
                      : "Self Assign Case"
                  }
                >
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() =>
                      handleCaseAssignment(
                        status === CaseStatusEnum.ASSIGNED,
                        caseDetail
                      )
                    }
                    okText={
                      status === CaseStatusEnum.ASSIGNED
                        ? "Yes, Remove assignment"
                        : "Yes, Assign to me"
                    }
                    cancelText="No"
                    placement="bottom"
                  >
                    <Switch
                      checked={status === CaseStatusEnum.ASSIGNED}
                      onChange={(checked) => {}}
                      className="ml-2"
                    />
                  </Popconfirm>
                </Tooltip>
              )}
              {caseDetail?.status === CaseStatusEnum.ASSIGNED && (
                <Tooltip title="Generate Report">
                  <Button
                    icon={<FormOutlined />}
                    className="ml-2"
                    onClick={() => {
                      setActiveCase(caseDetail);
                      handleToggleReportForm();
                    }}
                  />
                </Tooltip>
              )}
              {caseDetail?.status === CaseStatusEnum.REPORTED && (
                <Tooltip title="Download Report">
                  <Popover
                    destroyTooltipOnHide
                    overlayClassName="admin-cases__assignment-popover"
                    placement="bottomLeft"
                    content={
                      <div>
                        <Menu mode="vertical" className="border-none">
                          <Menu.Item
                            className="navbar-item m-0"
                            key="with_letterhead"
                            onClick={() => {
                              handleDownloadReport(
                                caseDetail,
                                DownloadFileTypeEnum.PDF,
                                true
                              );
                            }}
                          >
                            Download with Letterhead
                          </Menu.Item>
                          <Menu.Item
                            className="navbar-item m-0"
                            key="without_letterhead"
                            onClick={() => {
                              handleDownloadReport(
                                caseDetail,
                                DownloadFileTypeEnum.PDF,
                                false
                              );
                            }}
                          >
                            Download without Letterhead
                          </Menu.Item>
                          <Menu.Item
                            className="navbar-item m-0"
                            key="as_word"
                            onClick={() => {
                              handleDownloadReport(
                                caseDetail,
                                DownloadFileTypeEnum.WORD,
                                false
                              );
                            }}
                          >
                            Download as Word
                          </Menu.Item>
                        </Menu>
                      </div>
                    }
                    title="Download Report"
                    trigger="click"
                  >
                    <Button
                      loading={
                        activeCase?.id === caseDetail?.id && downloadLoading
                      }
                      icon={<DownloadOutlined />}
                      className="ml-2"
                    />
                  </Popover>
                </Tooltip>
              )}
              {/* /* Commented for Future use */}
              {/* {hasDoctorAccessToCase(UserRoleEnum.DOCTOR, caseDetail) && ( */}
              <Tooltip title="Download Case">
                <Button
                  loading={
                    activeCase?.id === caseDetail?.id && downloadZipLoading
                  }
                  icon={<CloudDownloadOutlined />}
                  className="ml-2"
                  onClick={() => {
                    handleDownloadDetails(caseDetail);
                  }}
                />
              </Tooltip>
              {adminFeatures?.includes(AdminFeatureEnum.DIGITAL_SHARING) && (
                <Tooltip title="Copy Share Link">
                  <Button
                    icon={<CopyOutlined />}
                    className="ml-2 clickable-icon"
                    onClick={() => {
                      Notification({
                        message: "Copied",
                        type: NotificationTypes.SUCCESS,
                      });
                      handleCaseShareLink(caseDetail);
                    }}
                  />
                </Tooltip>
              )}
              {/* /* Commented for Future use */}
              <Tooltip title="More Case Details">
                <Button
                  icon={<EllipsisOutlined />}
                  className="ml-2"
                  onClick={(event: any) => {
                    setActiveCase(caseDetail);
                    setShowCaseOverview(true);
                  }}
                />
              </Tooltip>
            </div>
          );
        },
        width: 200,
      },
    ]);
  }, [me, me?.adminType, caseDetails]);

  const updatedColumns = columns.map((col: any, index: any) => ({
    ...col,
    title: (
      <div className="resizable-header">
        {col.title}
        <div
          className="resizer"
          onMouseDown={(e) => handleMouseDown(e, index)}
        />
      </div>
    ),
  }));

  return (
    <div className="doctor-cases">
      <AppCard>
        <Row>
          <Col span={12}>
            <h2 className="mt-2">Cases</h2>
          </Col>
        </Row>
        <CaseFilterForm
          isDoctor
          formValues={formValues}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onChange={handleOnChange}
        />
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            dataSource={caseDetails}
            scroll={{ x: "max-content", y: `calc(100vh - 375px)` }}
            rowClassName="cursor-pointer"
            onRow={handleRowChange}
            columns={updatedColumns}
            pagination={{
              current: paginationMeta?.currentPage,
              pageSize: paginationMeta?.perPage,
              total: paginationMeta?.totalCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "15", "20", "50"],
            }}
            onChange={handlePageChange}
          />
        )}
        {caseDetails.length > 0 && <TagLegend />}
        <Modal
          destroyOnClose
          visible={showCaseOverview}
          className="primary-modal"
          onCancel={handleCloseCaseOverview}
          footer={null}
          maskClosable={false}
        >
          {activeCase?.id && (
            <CaseOverview
              activeCaseId={activeCase?.id}
              onCancel={handleCloseCaseOverview}
            />
          )}
        </Modal>
      </AppCard>

      <ContextMenu
        visible={menuVisible}
        position={menuPosition}
        selectedRow={selectedRow}
        onClose={() => setMenuVisible(false)}
        onUpdate={handleUpdateTags}
      />
      <Drawer
        destroyOnClose
        title={null}
        width="70%"
        onClose={handleToggleReportForm}
        visible={showReportForm}
        maskClosable={false}
        footer={null}
      >
        {activeCase?.id && (
          <ReportForm
            onClose={handleToggleReportForm}
            caseDetailId={activeCase.id}
            onPublish={() => handleFetchCases(formValues)}
          />
        )}
      </Drawer>
    </div>
  );
}

export default CaseContainer(DoctorCases);
