import React, { useEffect, useState } from "react";
import "./referringDoctorForm.scss";
import { Form, Formik, FormikValues } from "formik";
import { Button, Col, Row, Switch } from "antd";
import InputField from "../../../shared/components/InputField";
import { referringDoctorFormValidation } from "./referringDoctorFormValidation";
import { ReferringDoctor } from "../../../models/ReferringDoctor/referringDoctor.model";
import ReferringDoctorService from "../../../services/ReferringDoctor/referringDoctor.service";

interface ReferringDoctorFormProps {
  doctor: ReferringDoctor;
  onSuccess: (doctor: ReferringDoctor) => void;
}

function ReferringDoctorForm({ doctor, onSuccess }: ReferringDoctorFormProps) {
  const [formValues, setFormValues] = useState<ReferringDoctor>(doctor);

  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: FormikValues) => {
    const doctor = Object.assign(new ReferringDoctor(), values);
    setFormLoading(true);
    if (doctor.id) {
      ReferringDoctorService.updateReferringDoctor(
        doctor,
        (doctor: ReferringDoctor) => {
          onSuccess(doctor);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      doctor.password = `${doctor?.firstName || ""}${doctor?.lastName || ""}`;
      ReferringDoctorService.createReferringDoctor(
        doctor,
        (doctor: ReferringDoctor) => {
          onSuccess(doctor);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (doctor.id) {
      ReferringDoctorService.showReferringDoctor(
        doctor.id,
        (doctor: ReferringDoctor) => {
          setFormValues(doctor);
        },
        () => {},
        () => {}
      );
    }
  }, [doctor]);

  return (
    <div className="doctor-form">
      <h2 className="text-primary">
        {doctor.id ? "Edit Referring Doctor" : "Add Referring Doctor"}
      </h2>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={referringDoctorFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Row gutter={[30, 0]}>
                <Col span={12}>
                  <InputField
                    title="Name"
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Mobile"
                    type="text"
                    name="mobile_number"
                    placeholder="Enter Mobile"
                  />
                </Col>
              </Row>
              <div className="doctor-form__submit-wrapper text-right mt-3">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={formLoading}
                  disabled={!isValid || formLoading}
                >
                  {doctor?.id ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ReferringDoctorForm;
