import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";
import { AdminFeature } from "../AdminFeature/adminFeature.model";

export class ReferringDoctor {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("mobile_number", primitive()))
  mobile_number?: string;

  @serializable(alias("admin_id", primitive()))
  adminId?: string;

  @serializable(alias("country_code", primitive()))
  country_code?: string;

  @serializable(alias("admin_features", list(object(AdminFeature))))
  adminFeatures?: AdminFeature[] = [];
}
