import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AuthWrapper from "../views/Auth/AuthWrapper";
import ScrollToTop from "../shared/components/ScrollToTop";
import AppHeader from "../shared/components/AppHeader";
import AppSidebar from "../shared/components/AppSidebar";
import { AppRoutes } from "./routeConstants/appRoutes";
import ScanCentreList from "../views/ScanCenters/ScanCenterList";
import DoctorList from "../views/Doctors/DoctorList";
import ReferringDoctorList from "../views/ReferringDoctors/ReferringDoctorList";
import AdminList from "../views/Admins/AdminList";
import IssueList from "../views/Issues/IssueList";
import requireAuth from "../shared/components/HOC/requireAuth";
import AuthContainer from "../store/container/AuthContainer";
import { AuthReducerProps } from "../store/reducers/authReducer";
import AuthService from "../services/AuthService/auth.service";
import AppLoader from "../shared/components/AppLoader";
import { UserRoleEnum } from "../enums/userRole.enum";
import AdminCases from "../views/Cases/AdminCases";
import ScanCenterCases from "../views/Cases/ScanCenterCases";
import DoctorCases from "../views/Cases/DoctorCases";
import RestrictAccess from "../shared/components/RestrictedAccess";
import CaseViewer from "../shared/components/CaseViewer";
import DoctorSummary from "../views/Doctors/DoctorSummary";
import ScanCenterSummary from "../views/ScanCenters/ScanCenterSummary";
import ScanTypeList from "../views/ScanTypes/ScanTypeList";
import BodyPartList from "../views/BodyParts/BodyPartList";
import ReportTemplateList from "../views/ReportTemplates/ReportTemplateList";
import AdminProfile from "../views/Profile/AdminProfile";
import ScanCenterProfile from "../views/Profile/ScanCenterProfile";
import DoctorProfile from "../views/Profile/DoctorProfile";
import { AdminTypeEnum } from "../enums/AdminType.enum";
import ViewerWrapper from "../shared/components/ViewerWrapper";
import ViewerWrapperGuest from "../shared/components/GuestViewerWrapper";
import SharedPageRedirect from "../views/SharedPage/SharedPageRedirect";

interface AppRouterProps extends AuthReducerProps {}
function AppRouter({
  authenticated,
  userRole,
  adminType,
  setUserRole,
}: AppRouterProps) {
  const [showRoutes, setShowRoutes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(window.innerWidth < 2000); 

  const unauthenticatedRoutes = [
    {
      exact: false,
      path: AppRoutes.AUTH,
      component: AuthWrapper,
      authenticated: false,
    },
    {
      exact: false,
      path: AppRoutes.SHARED_PAGE,
      component: SharedPageRedirect,
      authenticated: false,
    },
    {
      exact: true,
      path: AppRoutes.CASE_DETAIL_GUEST,
      component: ViewerWrapperGuest,
      authenticated: false,
    }
  ];

  const authenticatedRoutes = [
    {
      exact: true,
      path: AppRoutes.HOME,
      component:
        userRole === UserRoleEnum.ADMIN
          ? AdminCases
          : userRole === UserRoleEnum.SCAN_CENTER
          ? ScanCenterCases
          : userRole === UserRoleEnum.DOCTOR
          ? DoctorCases
          : RestrictAccess,
      authenticated: true,
    },
    {
      exact: true,
      path: AppRoutes.SCAN_CENTRES,
      component: ScanCentreList,
      authenticated: true,
    },
    {
      exact: true,
      path: AppRoutes.DOCTORS,
      component: DoctorList,
      authenticated: true,
    },
    {
      exact: true,
      path: AppRoutes.REFERRING_DOCTORS,
      component: ReferringDoctorList,
      authenticated: true,
    },
    {
      exact: true,
      path: AppRoutes.DOCTOR_SUMMARY,
      component: DoctorSummary,
      authenticated: true,
    },
    {
      exact: true,
      path: AppRoutes.SCAN_CENTER_SUMMARY,
      component: ScanCenterSummary,
      authenticated: true,
    },
    // To be used for future use - KRISHNA
    // {
    //   exact: true,
    //   path: AppRoutes.ADMINS,
    //   component: AdminList,
    //   authenticated: true,
    // },
    // {
    //   exact: true,
    //   path: AppRoutes.SCAN_TYPES,
    //   component: ScanTypeList,
    //   authenticated: true,
    // },
    // {
    //   exact: true,
    //   path: AppRoutes.BODY_PARTS,
    //   component: BodyPartList,
    //   authenticated: true,
    // },
    // To be used for future use - KRISHNA
    {
      exact: true,
      path: AppRoutes.ISSUES,
      component: IssueList,
      authenticated: true,
    },
    {
      exact: true,
      path: AppRoutes.CASE_DETAIL,
      component: ViewerWrapper,
      authenticated: true,
    },
    {
      exact: true,
      path: AppRoutes.REPORT_TEMPLATES,
      component: ReportTemplateList,
      authenticated: true,
    },
    {
      exact: true,
      path: AppRoutes.PROFILE,
      component:
        userRole === UserRoleEnum.ADMIN
          ? AdminProfile
          : userRole === UserRoleEnum.SCAN_CENTER
          ? ScanCenterProfile
          : userRole === UserRoleEnum.DOCTOR
          ? DoctorProfile
          : RestrictAccess,
      authenticated: true,
    },
  ];

  useEffect(() => {
    if (!authenticated) {
      setShowRoutes(true);
    } else {
      if (!userRole) {
        if (localStorage.getItem("auth")) {
          const auth = JSON.parse(localStorage.getItem("auth") || "");
          setLoading(true);
          AuthService.getUserRole(
            auth.uid,
            (userRole: string, adminType?: AdminTypeEnum) => {
              setShowRoutes(true);
              setUserRole(userRole, adminType);
            },
            () => {},
            () => {
              setLoading(false);
            }
          );
        }
      }
    }
  }, [authenticated, userRole]);

  return (
    <div className="app-routes" id="appRoutes">
      {showRoutes && (
        <Router>
          <AppHeader collapsed={collapsed} setCollapsed={setCollapsed}/>
          <AppSidebar collapsed={collapsed} setCollapsed={setCollapsed}/>
          <ScrollToTop>
            <div>
              <Switch>
                {unauthenticatedRoutes.map((route, i) => {
                  return (
                    <Route
                      key={i}
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                    />
                  );
                })}
                <div className={`app-wrapper ${collapsed ? 'collapsed' : 'expanded'}`}>
                  {authenticatedRoutes.map((route, i) => {
                    return (
                      <Route
                        key={i}
                        exact={route.exact}
                        path={route.path}
                        component={requireAuth(route.component)}
                      />
                    );
                  })}
                </div>
              </Switch>
            </div>
          </ScrollToTop>
        </Router>
      )}
      <AppLoader loading={loading} />
    </div>
  );
}

export default AuthContainer(AppRouter);
