import Axios from "axios";
import { CaseDetail } from "../../models/CaseDetail/caseDetail.model";
import { CaseFilterParams } from "../../models/CaseFilterParams/caseFilterParams.model";
import moment from "moment";

export const buildFormData = (formData: any, data: any, parentKey?: any) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
};

export function convertJSONToFormData(data: any) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export function formatStatus(status: string) {
  return status.split("_").join(" ");
}

export function formatFileNameFromCaseDetail(caseDetail: CaseDetail) {
  const patientId = caseDetail?.patientId ?? "";
  const patientName = caseDetail?.patientName ?? "";
  const scanTypeName = caseDetail?.scanTypeName ?? "";
  return [patientId, patientName, scanTypeName].join("_");
}

export const uploadFileToS3 = async (file: File, signedUrl: string) => {
  return Axios.put(signedUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
};

export const getInitialFiltersFromURL = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const urlFilters: Partial<CaseFilterParams> = {};

  const paramParsers: Record<string, (value: string) => any> = {
    patientId: (v) => v,
    patientName: (v) => v,
    referringDoctor: (v) => v,
    scanCenterId: (v) => (v ? Number(v) : undefined),
    doctorId: (v) => (v ? Number(v) : undefined),
    scanTypeId: (v) => (v ? Number(v) : undefined),
    bodyPartId: (v) => (v ? Number(v) : undefined),
    tagId: (v) => (v ? Number(v) : undefined),
    status: (v) => v,
    fromDate: (v) => v,
    toDate: (v) => v,
    page: (v) => (v ? Number(v) : undefined),
    perPage: (v) => (v ? Number(v) : undefined),
  };

  Object.keys(paramParsers).forEach((key) => {
    const value = searchParams.get(key);
    if (value !== null) {
      urlFilters[key as keyof CaseFilterParams] = paramParsers[key](value);
    }
  });

  const initialFilters = new CaseFilterParams();

  // If fromDate is not in URL, set it to today's date
  if (!urlFilters.fromDate) {
    // Assuming you want the date in YYYY-MM-DD format
    urlFilters.fromDate = moment().format("YYYY-MM-DD");
  }

  // If toDate is not in URL, set it to today's date
  if (!urlFilters.toDate) {
    // Assuming you want the date in YYYY-MM-DD format
    urlFilters.toDate = moment().format("YYYY-MM-DD");
  }

  return Object.assign(initialFilters, urlFilters);
};

export const getCustomCellStyles = (maxWidth: number = 150) => {
  return () => ({
    style: {
      maxWidth, 
      minWidth: 100,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  })
}
