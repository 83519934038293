import { serializable, alias, primitive } from "serializr";

export class Tag {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("is_default", primitive()))
  isDefault?: boolean;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("color", primitive()))
  color?: string;

  @serializable(alias("admin_id", primitive()))
  adminId?: number;

  public get formattedName(): string {
    return this.name?.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ') ?? "";
  }
}
