import React, { useEffect, useRef, useState } from "react";
import "./viewerWrapper.scss";
import { useParams } from "react-router-dom";
import { Button, Drawer, Popover } from "antd";
import { EyeOutlined, FormOutlined, LeftOutlined } from "@ant-design/icons";
import ReportForm from "../ReportForm";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import AdminCaseService from "../../../services/Case/AdminCase/adminCase.service";
import DoctorCaseService from "../../../services/Case/DoctorCase/doctorCase.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import CryptoJS from "crypto-js";
import { useHistory } from "react-router-dom";
import ScanCenterCaseService from "../../../services/Case/ScanCenterCase/scanCenterCase.service";

interface ViewerWrapperProps extends AuthReducerProps {}

function ViewerWrapper({ userRole, user }: ViewerWrapperProps) {
  const history = useHistory<any>();

  const { caseId }: any = useParams();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [caseDetail, setCaseDetail] = useState<CaseDetail>();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const url: string | undefined = process.env.REACT_APP_VIEWER_BASE_URL ?? "";

  const auth = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth")!)
    : null;

  const secretKey = "LMUqneJBeU";

  const encryptedAuth = encryptAuthObject(auth, secretKey);

  // Function to encrypt the auth object
  function encryptAuthObject(authObject: any, secretKey: string): string {
    const authString = JSON.stringify(authObject);
    const encrypted = CryptoJS.AES.encrypt(authString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  }

  const jsonToUrlParams = (json: any) => {
    const params = new URLSearchParams(json).toString();
    return params;
  };

  const handleDrawerOpen = () => setDrawerVisible(true);

  const handleDrawerClose = () => setDrawerVisible(false);

  useEffect(() => {
    if (caseId && userRole) {
      const showCase =
        userRole === UserRoleEnum.ADMIN
          ? AdminCaseService.showAdminCase
          : userRole === UserRoleEnum.DOCTOR
          ? DoctorCaseService.showDoctorCase
          : ScanCenterCaseService.showScanCenterCase;
      showCase(
        Number(caseId),
        (caseDetail: CaseDetail) => {
          setCaseDetail(caseDetail);
        },
        () => {},
        () => {}
      );
    }
  }, [caseId, userRole]);

  // useEffect(() => {
  //   if (iframeRef.current) {
  //     const iframeWindow = iframeRef.current.contentWindow;

  //     iframeRef.current.onload = () => {
  //       iframeWindow?.postMessage({ auth,  authToken}, url);
  //     };
  //   }
  // }, []);

  const handleGoBack = () => {
    if (history.location.state && history.location.state.pathname) {
      const url = `${history.location.state.pathname}${history.location.state.search}`;
      history.push(url);
    } else {
      history.goBack();
    }
  };

  let showGenerateReport = false;
  if (userRole === UserRoleEnum.DOCTOR) {
    showGenerateReport = caseDetail?.status === CaseStatusEnum.ASSIGNED;
  } else {
    showGenerateReport =
      caseDetail?.status === CaseStatusEnum.TO_BE_APPROVED ||
      (caseDetail?.status === CaseStatusEnum.ASSIGNED &&
        user?.id === caseDetail?.assignedToAdminId);
  }
  return (
    <div className={`viewer-wrapper ${drawerVisible ? "drawer-open" : ""}`}>
      <div className="viewer-wrapper__generate-wrapper">
        {showGenerateReport && (
          <Button type="primary" onClick={handleDrawerOpen}>
            <FormOutlined />
            Generate Report
          </Button>
        )}
        <Popover
          placement="bottomLeft"
          content={<div>{caseDetail?.parsedPatientHistory || "N/A"}</div>}
          title={null}
        >
          <Button type="primary" className="ml-3">
            Case History
          </Button>
        </Popover>
      </div>
      {/* <Button
        htmlType="button"
        type="primary"
        className="viewer-wrapper__back-wrapper"
        onClick={handleGoBack}
      >
        <LeftOutlined /> Back
      </Button> */}
      <div className="viewer-wrapper__iframe-wrapper">
        {encryptedAuth && (
          <iframe
            ref={iframeRef}
            src={`${url}?caseId=${caseId}&auth=${encryptedAuth}&guest=false`}
            title="Case viewer"
            className="viewer-wrapper__iframe"
          />
        )}
      </div>
      <Drawer
        className="viewer-wrapper__generate-report-drawer"
        title={null}
        width="100%"
        onClose={handleDrawerClose}
        visible={drawerVisible}
        maskClosable={false}
        footer={null}
      >
        {caseDetail && <ReportForm fromViewer caseDetailId={caseDetail.id} />}
      </Drawer>
    </div>
  );
}

export default AuthContainer(ViewerWrapper);
