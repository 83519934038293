import React, { useEffect, useState } from "react";
import "./caseForm.scss";
import { CaseDetail } from "../../../../models/CaseDetail/caseDetail.model";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "antd";
import InputField from "../../../../shared/components/InputField";
import FileUpload from "../../../../shared/components/FileUpload";
import { caseFileValidation, caseFormValidation } from "./caseFormValidation";
import {
  getDropdownOptions,
  IDropdownOptions,
} from "../../../../shared/utils/dropdownUtils";
import DropdownField from "../../../../shared/components/DropdownField";
import { DropdownModeEnum } from "../../../../enums/dropdownMode.enum";
import ScanCenterCaseService from "../../../../services/Case/ScanCenterCase/scanCenterCase.service";
import { CaseBodyPart } from "../../../../models/CaseBodyPart/caseBodyPart.model";
import ScanTypeService from "../../../../services/ScanType/scanType.service";
import { ScanType } from "../../../../models/ScanType/scanType.model";
import BodyPartService from "../../../../services/BodyPart/bodyPart.service";
import { BodyPart } from "../../../../models/BodyPart/bodyPart.model";
import CaseContainer from "../../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../../store/reducers/caseReducer";
import { ScanCenterBranch } from "../../../../models/ScanCenterBranch/scanCenterBranch.model";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import ProgressBar from "../../../../shared/components/ProgressBar";
import AttachmentUpload from "../../../../shared/components/AttachmentUpload";
import { uploadFileToS3 } from "../../../../shared/utils/dataFormatConverter";
import { Attachment } from "../../../../models/Attachment/attachment.model";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import { UserRoleEnum } from "../../../../enums/userRole.enum";
import AdminCaseService from "../../../../services/Case/AdminCase/adminCase.service";

interface CaseFormProps extends CaseReducerProps, AuthReducerProps {
  caseDetailId: number;
  onSuccess: (caseDetail: CaseDetail) => void;
  onClose?: () => void;
}

function CaseForm({
  caseDetailId,
  user,
  userRole,
  onSuccess,
  onClose,
  setPending,
}: CaseFormProps) {
  const [formLoading, setFormLoading] = useState(false);

  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [caseDetail, setCaseDetail] = useState(new CaseDetail());

  const [formValues, setFormValues] = useState(new CaseDetail());

  const [showFileUpload, setShowFileUpload] = useState(false);

  const genderOptions: IDropdownOptions[] = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Transgender", value: "transgender" },
  ];

  const contrastOptions: IDropdownOptions[] = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const [progressPercent, setProgressPercent] = useState(0);

  const [scanTypeOptions, setScanTypeOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [bodyPartOptions, setBodyPartOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [scanCenterBranchOptions, setScanCenterBranchOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [presignedUrl, setCasePresignedUrl] = useState<string>();

  const [fileName, setFileName] = useState<string>();

  const [recentlyAddedCaseId, setRecentlyAddedCaseId] = useState<number>();

  const [newAttachments, setNewAttachments] = useState<Attachment[]>([]);

  const [existingAttachments, setExistingAttachments] = useState<Attachment[]>(
    []
  );

  const handleSubmit = (values: CaseDetail) => {
    const caseDetail = Object.assign(new CaseDetail(), values);
    caseDetail.caseBodyParts = caseDetail.caseBodyPartIds.map((bodyPartId) =>
      Object.assign(new CaseBodyPart(), {
        bodyPartId,
      })
    );
    caseDetail.caseAttachments = [...newAttachments, ...existingAttachments];
    setFormLoading(true);
    if (caseDetail.id) {
      const isAdmin = userRole === UserRoleEnum.ADMIN;
      const apiMethod = isAdmin
        ? AdminCaseService.updateCase
        : ScanCenterCaseService.updateScanCenterCase;
      apiMethod(
        caseDetail,
        (caseDetail: CaseDetail) => {
          onSuccess(caseDetail);
          if (isAdmin) {
            onClose && onClose();
          } else {
            setShowFileUpload(true);
          }
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      ScanCenterCaseService.createScanCenterCase(
        caseDetail,
        (caseDetail: CaseDetail) => {
          setRecentlyAddedCaseId(caseDetail?.id);
          if (caseDetail?.id) {
            ScanCenterCaseService.getCasePresignedURL(
              caseDetail?.id,
              ({ url, fileName }: { url: string; fileName: string }) => {
                setCasePresignedUrl(url);
                setFileName(fileName);
              },
              () => {},
              () => {}
            );
          }
          onSuccess(caseDetail);
          setShowFileUpload(true);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const handleFileSubmit = async (values: { zipFile?: File }) => {
    const { zipFile } = values;
    if (presignedUrl && zipFile) {
      setFileUploadLoading(true);
      const xhr = new XMLHttpRequest();
      const formData = new FormData();
      formData.append("file", zipFile);
      xhr.open("PUT", presignedUrl);
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setProgressPercent(percentComplete);
        }
      };
      xhr.addEventListener("load", (e) => {
        setFileUploadLoading(false);
        if (recentlyAddedCaseId && fileName) {
          ScanCenterCaseService.updateFileUploadStatus(
            recentlyAddedCaseId,
            fileName,
            () => {
              onClose && onClose();
            },
            () => {},
            () => {}
          );
        }
      });
      xhr.addEventListener("error", () => {
        setFileUploadLoading(false);
      });
      xhr.send(formData);
    }
  };

  useEffect(() => {
    ScanTypeService.fetchScanTypes(
      (scanTypes: ScanType[]) => {
        setScanTypeOptions(getDropdownOptions(scanTypes, "name", "id"));
      },
      () => {},
      () => {}
    );
    if (user && user?.id && userRole === UserRoleEnum.SCAN_CENTER) {
      ScanTypeService.fetchScanCenterBranches(
        Number(user.id),
        (scanCenterBranches: ScanCenterBranch[]) => {
          setScanCenterBranchOptions(
            getDropdownOptions(scanCenterBranches, "name", "id")
          );
        },
        () => {},
        () => {}
      );
    }

    BodyPartService.fetchBodyParts(
      (bodyParts: BodyPart[]) => {
        setBodyPartOptions(getDropdownOptions(bodyParts, "name", "id"));
      },
      () => {},
      () => {}
    );
  }, [userRole]);

  useEffect(() => {
    if (caseDetailId) {
      const apiMethod =
        userRole === UserRoleEnum.ADMIN
          ? AdminCaseService.showAdminCase
          : ScanCenterCaseService.showScanCenterCase;
      apiMethod(
        caseDetailId,
        (caseDetail: CaseDetail) => {
          setCaseDetail(caseDetail);
          setExistingAttachments(caseDetail.caseAttachments ?? []);
        },
        () => {},
        () => {}
      );
    }
  }, [caseDetailId]);

  useEffect(() => {
    if (caseDetail && caseDetail.caseBodyParts) {
      caseDetail.caseBodyPartIds = caseDetail.caseBodyParts.map(
        (caseBodyPart: CaseBodyPart) => caseBodyPart.bodyPartId || 0
      );
      setFormValues(Object.assign(new CaseDetail(), { ...caseDetail }));
    }
  }, [caseDetail]);

  const handleUploadAttachments = async (files: File[]) => {
    if (caseDetailId) {
      const getPresignedURL =
        userRole === UserRoleEnum.SCAN_CENTER
          ? ScanCenterCaseService.getCaseAttachmentPresignedURL
          : AdminCaseService.getCaseAttachmentPresignedURL;

      const presignedURLs = await Promise.all(
        files.map((file) => getPresignedURL(caseDetailId, file.name))
      );

      const s3Objects = await Promise.all(
        files.map((file, i) => uploadFileToS3(file, presignedURLs[i].data.url))
      );

      const attachments = s3Objects.map((s3Object, i) => {
        const attachment = new Attachment();
        attachment.imageUrl = presignedURLs[i].data.file_name;
        attachment.fileName = s3Object.config.data.name;
        return attachment;
      });

      setNewAttachments([...newAttachments, ...attachments]);
    }
  };

  const handleDeleteNewAttachment = (i: number) => {
    newAttachments.splice(i, 1);
    setNewAttachments([...newAttachments]);
  };

  const handleDeleteExistingAttachment = async (
    i: number,
    userRole: UserRoleEnum
  ) => {
    const attachment = existingAttachments[i];
    const deleteAttachmentService =
      userRole === UserRoleEnum.SCAN_CENTER
        ? ScanCenterCaseService.deleteCaseAttachment
        : AdminCaseService.deleteCaseAttachment;

    const response = await deleteAttachmentService(
      caseDetailId,
      attachment?.imageUrl ?? ""
    );

    if (response.status === 200) {
      Notification({
        message: "Attachment deleted",
        type: NotificationTypes.SUCCESS,
      });
      existingAttachments.splice(i, 1);
      setExistingAttachments([...existingAttachments]);
    }
  };

  return (
    <div className="case-form">
      <h2 className="text-primary">
        {caseDetailId ? "Edit Case" : "Add Case"}
      </h2>
      {showFileUpload ? (
        <Formik
          initialValues={{ zipFile: undefined }}
          onSubmit={handleFileSubmit}
          enableReinitialize
          validationSchema={caseFileValidation}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Row gutter={[30, 0]}>
                  <Col span={12}>
                    <FileUpload
                      name="zipFile"
                      title="Upload attachment"
                      accept=".zip"
                      setFieldValue={setFieldValue}
                      value={values.zipFile}
                    />
                  </Col>
                  {progressPercent > 0 && (
                    <Col span={24}>
                      <div style={{ margin: "1rem 0 0" }}>Upload Progress</div>
                      <ProgressBar
                        progress={parseInt(progressPercent.toString(), 10)}
                      />
                    </Col>
                  )}
                </Row>
                <div className="case-form__submit-wrapper text-right mt-3">
                  {progressPercent === 100 ? (
                    <Button type="primary" htmlType="button">
                      Close
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={fileUploadLoading}
                      disabled={!isValid || fileUploadLoading}
                    >
                      Upload File
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={caseFormValidation}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            return (
              <Form>
                <Row gutter={[30, 0]}>
                  <Col span={12}>
                    <InputField
                      title="Patient ID"
                      type="text"
                      name="patientId"
                      placeholder="Enter Patient ID"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Patient name"
                      type="text"
                      name="patientName"
                      placeholder="Enter Patient name"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      placeHolder="Select Scan Type"
                      showSearch
                      title="Scan Type"
                      name="scanTypeId"
                      setFieldValue={setFieldValue}
                      options={scanTypeOptions}
                      value={values.scanTypeId}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      placeHolder="Select Body parts"
                      showSearch
                      mode={DropdownModeEnum.MULTIPLE}
                      title="Body parts"
                      name="caseBodyPartIds"
                      setFieldValue={setFieldValue}
                      options={bodyPartOptions}
                      value={values.caseBodyPartIds}
                    />
                  </Col>
                  {userRole === UserRoleEnum.SCAN_CENTER && (
                    <Col span={12}>
                      <DropdownField
                        placeHolder="Select Scan Center Branch"
                        showSearch
                        title="Scan Center Branch"
                        name="scanCenterBranchId"
                        setFieldValue={setFieldValue}
                        options={scanCenterBranchOptions}
                        value={values.scanCenterBranchId}
                      />
                    </Col>
                  )}
                  <Col span={12}>
                    <InputField
                      title="Age"
                      type="number"
                      name="age"
                      placeholder="Enter age"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      placeHolder="Select Gender"
                      showSearch
                      title="Gender"
                      name="gender"
                      setFieldValue={setFieldValue}
                      options={genderOptions}
                      value={values.gender}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Study Description"
                      type="text"
                      name="studyDescription"
                      placeholder="Enter Study description"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      placeHolder="Select Contrast"
                      showSearch
                      title="Contrast"
                      name="contrast"
                      setFieldValue={setFieldValue}
                      options={contrastOptions}
                      value={values.contrast}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Referring Doctor"
                      type="text"
                      name="referringDoctor"
                      placeholder="Enter Referring Doctor"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Patient History"
                      type="textarea"
                      name="patientHistory"
                      placeholder="Enter Patient History"
                    />
                  </Col>
                  {caseDetailId && (
                    <Col span={24}>
                      <AttachmentUpload
                        onDeleteNewAttachment={handleDeleteNewAttachment}
                        onDeleteExistingAttachment={
                          handleDeleteExistingAttachment
                        }
                        onUpload={handleUploadAttachments}
                        name="attachments"
                        title="Patient History attachments"
                        placeholder="Upload JPEG, JPG and PNG Files"
                        multiple
                        accept="image/jpeg,image/jpg,image/png"
                        existingAttachments={existingAttachments}
                        newAttachments={values?.attachments}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  )}
                </Row>
                <div className="case-form__submit-wrapper text-right mt-3">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={formLoading}
                    disabled={!isValid || formLoading}
                  >
                    {caseDetailId ? "Update" : "Add"}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}

export default AuthContainer(CaseContainer(CaseForm));
