import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { ReportTemplate } from "../../models/ReportTemplate/reportTemplate.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class ReportTemplateService {
  static fetchReportTemplates(
    searchText: string,
    scanTypeId: number = 0,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.REPORT_TEMPLATES, {
        params: {
          search: searchText,
          scan_type_id: scanTypeId !== 0 ? scanTypeId : "",
        },
      })
      .then((response) => {
        const reportTemplates = deserialize(
          ReportTemplate,
          response.data["templates"]
        );
        onSuccess(reportTemplates);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showReportTemplate(
    reportTemplateId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.REPORT_TEMPLATES + "/" + reportTemplateId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const reportTemplate = deserialize(
          ReportTemplate,
          response.data["template"]
        );
        onSuccess(reportTemplate);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createReportTemplate(
    reportTemplate: ReportTemplate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const reportTemplateJSON = { template: serialize(reportTemplate) };
    axiosInstance
      .post(ApiRoutes.REPORT_TEMPLATES, reportTemplateJSON)
      .then((response) => {
        Notification({
          message: "Template created",
          type: NotificationTypes.SUCCESS,
        });
        const reportTemplate = deserialize(
          ReportTemplate,
          response.data["template"]
        );
        onSuccess(reportTemplate);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateReportTemplate(
    reportTemplate: ReportTemplate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.REPORT_TEMPLATES + "/" + reportTemplate.id;
    const reportTemplateJSON = { template: serialize(reportTemplate) };
    axiosInstance
      .put(API_URL, reportTemplateJSON)
      .then((response) => {
        Notification({
          message: "Template updated",
          type: NotificationTypes.SUCCESS,
        });
        const reportTemplate = deserialize(
          ReportTemplate,
          response.data["template"]
        );
        onSuccess(reportTemplate);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteReportTemplate(
    reportTemplateId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.REPORT_TEMPLATES + "/" + reportTemplateId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
