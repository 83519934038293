import { Button, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { WhatsAppOutlined, SendOutlined } from "@ant-design/icons";
import "./caseSharing.scss";
import { ReferringDoctor } from "../../../models/ReferringDoctor/referringDoctor.model";
import ReferringDoctorService from "../../../services/ReferringDoctor/referringDoctor.service";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import Notification from "../Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import SharedService from "../../../services/Shared/shared.service";
import { WhatsappSharingTypeEnum } from "../../../enums/WhatsappSharingType.enum";

interface CaseSharingProps {
  caseDetail?: CaseDetail;
}

function CaseSharing({ caseDetail }: CaseSharingProps) {
  const [phoneNumber, setPhoneNumber] = useState<string>();

  const [referringDoctorId, setReferringDoctorId] = useState<number>();

  const [mobileNumberLoading, setMobileNumberLoading] = useState<boolean>(
    false
  );

  const [referringDoctorLoading, setReferringDoctorLoading] = useState<boolean>(
    false
  );

  const [referringDoctors, setReferringDoctors] = useState<ReferringDoctor[]>(
    []
  );

  const handleSendWhatsapp = (sharingType: WhatsappSharingTypeEnum) => {
    if (
      sharingType === WhatsappSharingTypeEnum.MOBILE_NUMBER &&
      (!phoneNumber || phoneNumber.length !== 10)
    ) {
      Notification({
        message: "Please enter a valid mobile number",
        type: NotificationTypes.ERROR,
      });
      return;
    }
    if (
      sharingType === WhatsappSharingTypeEnum.REFERRING_DOCTOR &&
      !referringDoctorId
    ) {
      Notification({
        message: "Please select a doctor",
        type: NotificationTypes.ERROR,
      });
      return;
    }
    if (caseDetail?.id) {
      const data: { mobile_number?: string; referring_doctor_id?: number } = {};
      if (sharingType === WhatsappSharingTypeEnum.MOBILE_NUMBER) {
        data["mobile_number"] = phoneNumber;
      } else {
        data["referring_doctor_id"] = referringDoctorId;
      }
      if (sharingType === WhatsappSharingTypeEnum.MOBILE_NUMBER) {
        setMobileNumberLoading(true);
      } else {
        setReferringDoctorLoading(true);
      }
      SharedService.sendWhatsappShareLink(
        Number(caseDetail?.id),
        data,
        () => {},
        () => {},
        () => {
          setMobileNumberLoading(false);
          setReferringDoctorLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    ReferringDoctorService.fetchReferringDoctors(
      (referringDoctors: ReferringDoctor[]) => {
        setReferringDoctors(referringDoctors);
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="case-sharing">
      <div className="case-sharing__label">
        <WhatsAppOutlined />
        Send to Whatsapp
      </div>
      <div className="case-sharing__value">
        <div>
          <Input
            placeholder="Enter mobile number"
            type={"number"}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <Button
            icon={<SendOutlined />}
            loading={mobileNumberLoading}
            onClick={() => {
              handleSendWhatsapp(WhatsappSharingTypeEnum.MOBILE_NUMBER);
            }}
          />
        </div>
        <div>
          <Select
            options={referringDoctors.map((doctor) => ({
              label: doctor.name ?? "",
              value: doctor.id ?? "",
            }))}
            placeholder="Referring Doctor"
            onChange={(id: number) => {
              setReferringDoctorId(id);
            }}
          />
          <Button
            icon={<SendOutlined />}
            loading={referringDoctorLoading}
            onClick={() => {
              handleSendWhatsapp(WhatsappSharingTypeEnum.REFERRING_DOCTOR);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CaseSharing;
