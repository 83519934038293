import axiosInstance from "../../interceptor/axiosInstance";
import { ReferringDoctor } from "../../models/ReferringDoctor/referringDoctor.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import { Summary } from "../../models/Summary/summary.model";
import { SummaryFilterParams } from "../../models/SummaryFilterParams/summaryFilterParams.model";
import { Admin } from "../../models/Admin/admin.model";
import { UserRoleEnum } from "../../enums/userRole.enum";

export default class ReferringDoctorService {
  static fetchReferringDoctors(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.REFERRING_DOCTORS)
      .then((response) => {
        const doctors = deserialize(ReferringDoctor, response.data["referring_doctors"]);
        onSuccess(doctors);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showReferringDoctor(
    doctorId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.REFERRING_DOCTORS + "/" + doctorId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const doctor = deserialize(ReferringDoctor, response.data["referring_doctor"]);
        onSuccess(doctor);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showReferringDoctorSummary(
    filterParams: SummaryFilterParams,
    doctorId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(filterParams);
    const API_URL = ApiRoutes.ADMIN_DOCTOR_SUMMARY.replace(
      ":doctorId",
      doctorId.toString()
    );
    axiosInstance
      .get(API_URL, {
        params,
      })
      .then((response) => {
        const doctorSummary = deserialize(Summary, response.data);
        onSuccess(doctorSummary);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createReferringDoctor(
    doctor: ReferringDoctor,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    doctor.country_code = "91"
    const doctorForm = convertJSONToFormData({ referring_doctor: serialize(doctor) });
    axiosInstance
      .post(ApiRoutes.REFERRING_DOCTOR_SIGNUP, doctorForm)
      .then((response) => {
        Notification({
          message: "ReferringDoctor created!",
          type: NotificationTypes.SUCCESS,
        });
        const doctor = deserialize(ReferringDoctor, response.data["referring_doctors"]);
        onSuccess(doctor);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateReferringDoctor(
    doctor: ReferringDoctor,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const doctorJSON = { referring_doctor: serialize(doctor) };
    const doctorForm = convertJSONToFormData(doctorJSON);
    const API_URL = ApiRoutes.REFERRING_DOCTORS + "/" + doctor.id;
    axiosInstance
      .put(API_URL, doctorForm)
      .then((response) => {
        Notification({
          message: "ReferringDoctor updated!",
          type: NotificationTypes.SUCCESS,
        });
        const doctor = deserialize(ReferringDoctor, response.data["referring_doctors"]);
        onSuccess(doctor);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteReferringDoctor(
    doctorId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DOCTORS + "/" + doctorId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
